import * as types from './mutation-types';


export default {
  [types.LIST_ATTENDENCES]: (state, { attendences }) => {
    state.attendences = attendences;
  },
  [types.LIST_FINISHED_ATTENDENCES]: (state, { attendences }) => {
    state.finishedAttendences = attendences;
  },
  [types.SET_LOADING_ATTENDENCES]: (state, { isLoadingAttendences }) => {
    state.isLoadingAttendences = isLoadingAttendences;
  },
  [types.EDIT_ATTENDENCE]: (state, { attendence }) => {
    const index = state.attendences.findIndex(t => t.id === attendence.id);
    state.attendences.splice(index, 1, attendence);
  },
  [types.EDIT_ATTENDENCE_STATUS]: (state, { attendence }) => {
    const index = state.attendences.findIndex(t => t.id === attendence.id);
    state.attendences.splice(index, 1, attendence);
  },
  [types.DELETE_ATTENDENCE]: (state, { attendenceId }) => {
    const index = state.attendences.findIndex(t => t.id === attendenceId);
    state.attendences.splice(index, 1);
  },
  [types.CREATE_ATTENDENCE]: (state, { attendence, client, user, chat }) => {
    attendence.client = client
    attendence.user = user
    attendence.chat = chat
    attendence.item = []
    attendence.historics = []
    attendence.payment = []
    attendence.comments = []
    attendence.schedules = []
    attendence.files = []
    attendence.responsibles = []
    attendence.responsibles.push(user)
    state.attendences.push(attendence)
  },
  [types.LIST_USERS]: (state, { users }) => {
    state.users = users;
  },
  [types.SET_LOADING_USERS]: (state, { isLoadingUsers }) => {
    state.isLoadingUsers = isLoadingUsers;
  },
  [types.LIST_ITEMS]: (state, { items }) => {
    state.attendenceItems = items;
  },
  [types.CREATE_ITEM]: (state, { item }) => {
    state.attendences.find(el => el.id == item.attendenceId).item.push(item)
  },
  [types.EDIT_ITEM]: (state, { item }) => {
    const attendence = state.attendences.find(el => el.id == item.attendenceId)
    const indice = attendence.item.findIndex(t => t.id === item.id);
    attendence.item.splice(indice, 1, item);
    const atteIndex = state.attendences.findIndex(el => el.id == attendence.id)
    state.attendences.splice(atteIndex, 1, attendence)
  },
  [types.DELETE_ITEM]: (state, { itemId, attendenceId }) => {
    const atte = state.attendences.find(el => el.id == attendenceId)
    const scheIndex = atte.item.findIndex(el => el.id == itemId)
    atte.item.splice(scheIndex, 1)
    const index = state.attendences.findIndex(el => el.id == atte.id)
    state.attendences.splice(index, 1, atte)
  },
  [types.CREATE_PAYMENT]: (state, { payment }) => {
    const attendence = state.attendences.find(el => el.id == payment.attendenceId)
    attendence.payment.push(payment)
    const index = state.attendences.findIndex(el => el.id == attendence.id)
    state.attendences.splice(index, 1, attendence)
  },
  [types.EDIT_PAYMENT]: (state, { payment }) => {
    const indice = state.attendence[payment.attendenceId].payments.findIndex(t => t.id === payment.id);
    state.attendence[payment.attendenceId].payments.splice(indice, 1, payment);
  },
  [types.DELETE_PAYMENT]: (state, { attendenceId }) => {
    const atte = state.attendences.find(el => el.id == attendenceId)
    atte.payment = []
    const index = state.attendences.findIndex(el => el.id == attendenceId)
    state.attendences.splice(index, 1, atte)
  },
  [types.CREATE_HISTORIC]: (state, { historic, loggedUser }) => {
    historic.user = loggedUser
    const attendence = state.attendences.find(el => el.id == historic.attendenceId)
    attendence.historics.push(historic)
    const index = state.attendences.findIndex(el => el.id == attendence.id)
    state.attendences.splice(index, 1, attendence)
  },
  [types.LIST_STATUSES]: (state, { statuses }) => {
    state.statuses = statuses
  },
  [types.EDIT_STATUS]: (state, { status }) => {
    const indice = state.statuses.findIndex(t => t.id === status.id);
    state.statuses.splice(indice, 1, status);
  },
  [types.CREATE_COMMENTARY]: (state, { commentary, loggedUser }) => {
    commentary.user = loggedUser
    const attendence = state.attendences.find(el => el.id == commentary.attendenceId)
    attendence.comments.push(commentary)
    const index = state.attendences.findIndex(el => el.id == attendence.id)
    state.attendences.splice(index, 1, attendence)
  },

  [types.CREATE_SCHEDULE]: (state, { schedule }) => {
    const attendence = state.attendences.find(el => el.id == schedule.attendenceId)
    attendence.schedules.push(schedule)
    const index = state.attendences.findIndex(el => el.id == attendence.id)
    state.attendences.splice(index, 1, attendence)
  },
  [types.EDIT_SCHEDULE]: (state, { schedule }) => {
    const attendence = state.attendences.find(el => el.id == schedule.attendenceId)
    const indice = attendence.schedules.findIndex(t => t.id === schedule.id);
    attendence.schedules.splice(indice, 1, schedule);
    const atteIndex = state.attendences.findIndex(el => el.id == attendence.id)
    state.attendences.splice(atteIndex, 1, attendence)
  },
  [types.DELETE_SCHEDULE]: (state, { scheduleId, attendenceId }) => {
    const atte = state.attendences.find(el => el.id == attendenceId)
    const scheIndex = atte.schedules.findIndex(el => el.id == scheduleId)
    atte.schedules.splice(scheIndex, 1)
    const index = state.attendences.findIndex(el => el.id == atte.id)
    state.attendences.splice(index, 1, atte)
  },
  [types.CREATE_FILE]: (state, { file }) => {
    const attendence = state.attendences.find(el => el.id == file.attendenceId)
    attendence.files.push(file)
    const index = state.attendences.findIndex(el => el.id == attendence.id)
    state.attendences.splice(index, 1, attendence)
  },
  [types.EDIT_FILE]: (state, { file }) => {
    const attendence = state.attendences.find(el => el.id == file.attendenceId)
    const indice = attendence.files.findIndex(t => t.id === file.id);
    attendence.files.splice(indice, 1, file);
    const atteIndex = state.attendences.findIndex(el => el.id == attendence.id)
    state.attendences.splice(atteIndex, 1, attendence)
  },
  [types.DELETE_FILE]: (state, { fileId, attendenceId }) => {
    const atte = state.attendences.find(el => el.id == attendenceId)
    const scheIndex = atte.files.findIndex(el => el.id == fileId)
    atte.files.splice(scheIndex, 1)
    const index = state.attendences.findIndex(el => el.id == atte.id)
    state.attendences.splice(index, 1, atte)
  },
  [types.CREATE_RESPONSIBLE]: (state, { responsible }) => {
    const attendence = state.attendences.find(el => el.id == responsible.attendenceId)
    attendence.responsibles.push(responsible.user)
    const index = state.attendences.findIndex(el => el.id == attendence.id)
    state.attendences.splice(index, 1, attendence)
  },

  [types.RESET_UNREADCOUNT_CHAT]: (state, chat) => {
    state.attendences.map((el) => {
      if (el?.chat.id == chat.id) {
        el.chat = chat;
      }
    })
  },

  [types.LIST_ENVIRONMENTS]: (state, { environments }) => {
    state.environments = environments
  },
  [types.EDIT_ENVIRONMENT]: (state, { environment }) => {
    const index = state.environments.findIndex(t => t.id === environment.id);
    state.environments.splice(index, 1, environment);
  },
  [types.CREATE_ENVIRONMENT]: (state, { environment }) => {
    state.environments.push(environment)
  },
  [types.DELETE_ENVIRONMENT]: (state, { environmentId }) => {
    const index = state.environments.findIndex(t => t.id === environmentId);
    state.environments.splice(index, 1);
  },
  [types.MESSAGE_RECEIVED]: (state, msg) => {
    let attendence = state.attendences.find((el) => el?.chat?._serialized == msg.from);
    if (attendence?.chat)
      state.attendences = state.attendences.map((el) => {
        if (el?.id == attendence?.id) {
          el.chat.unreadCount++;
          el.chat.timestamp = msg.timestamp;
          return el
        }else { return el }
      })
  }
};
