<template>
  <v-app-bar
    permanent
    mini-variant
    mini-variant-width="30"
    class="menu"
    touchless
    app
    height="60"
    elevate-on-scroll
    flat
  >
    <div class="menu-header">
      <img class="logoImage" src="../../assets/img/logoNath.png" alt="" />
    </div>
    <v-list class="content item-list pl-10" dense nav height="100%">
      <v-list-item
        v-for="item in items"
        v-bind:key="item.id"
        class="item"
        height="100%"
      >
        <router-link :to="item.routeName">
          <v-list-item-action
            class="item-action"
            v-bind="attrs"
            v-on="on"
            height="100%"
          >
            <p
              style="color: #177dff; font-weight: 600"
              v-if="item.routeName == $route.path"
            >
              {{ item.title }}
            </p>
            <p v-if="item.routeName != $route.path && hasPermission(item.permission)" class="black--text">{{ item.title }}</p>
          </v-list-item-action>
        </router-link>
      </v-list-item>
    </v-list>
    <v-spacer></v-spacer>
    <div class="content right-items">
      <div>
        <v-badge color="#FFA290" dot overlap>
          <v-icon>mdi-bell-outline</v-icon>
        </v-badge>
      </div>
      <div class="menu-header">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <AppUserCard v-bind="attrs" v-on="on" />
          </template>
          <span>Configurações do usuário</span>
        </v-tooltip>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import CompanyService from '../../modules/configurations/services/configuration-service';

import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapActions } = createNamespacedHelpers('orders');

export default {
  methods: {
    ...mapActions(['playNotification', 'stopNotification']),
  },
  computed: {
    ...mapGetters(['registered']),
  },
  watch: {
    registered(newValue) {
      // alert(newValue.length);
      if (newValue.length > 0) {
        this.playNotification();
      } else {
        this.stopNotification();
      }
    },
  },
  data() {
    return {
      items: [
        {
          id: 1,
          title: 'Atendimentos',
          routeName: '/atendimentos',
          permission: "view_attendence",
          exact: true,
        },
        {
          id: 2,
          title: 'Clientes',
          routeName: '/clientes',
          permission: "view_client",
          exact: true,
        },
        {
          id: 3,
          title: 'Agenda',
          routeName: '/agenda',
          permission: "view_schedule",
          exact: true,
        },
        {
          id: 5,
          title: 'Finalizados',
          routeName: '/finalizados',
          exact: true,
        },
        {
          id: 6,
          title: 'Configurações',
          routeName: '/configuracoes',
          permission: "view_configuration",
          exact: true,
        },
        {
          id: 7,
          title: 'Chat',
          routeName: '/chat',
          permission: "view_chat",
          exact: true,
        },
      ],
      company: {},
    };
  },
  async created() {
    try {
      const companyId = window.localStorage.getItem('companyId');
      const response = await CompanyService.get(companyId);
      this.company = response.data;
    } catch (err) {
      //console.log(err.response);
    }
  },
};
</script>

<style>
.logout-button {
  transform: rotate(180deg);
}
.menu-header{
  display: flex;
}

.menu-header .logoImage{
  align-items: center;
  width: 70px;
  height: auto
}
/* .v-navigation-drawer {
  background: var(--v-primary-base) !important;
} */

.v-navigation-drawer .v-navigation-drawer__border {
  background-color: var(--v-border-base) !important;
}

.menu-footer {
  transform: rotate(90deg);
}

.content {
  display: flex;
  align-items: center;
}

.item-list {
  background-color: transparent !important;
  padding: 0 !important;
}

.item {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
}

.item > a {
  min-height: 100%;
  display: flex;
}

.item > a > div {
  margin: 0px !important;
}

.item > a > div > p {
  padding-left: 20px;
  padding-right: 20px;
}

.item-action {
  margin: 0;
}

.item-action > p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}

.menu > .v-toolbar__content {
  padding: 0;
}

.menu > a > * {
  height: 100% !important;
}

.v-badge {
  height: fit-content;
  padding: 0 !important;
}

.content.right-items {
  display: flex;
  justify-content: space-between;
  min-width: 80px;
}

.content.right-items > * {
  max-width: 70%;
  padding-left: 28px;
}

.menu {
  padding-right: 30px !important;
  padding-left: 30px;
  background-color: white !important;
}
</style>
