<template>
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        :height="iconSize" 
        viewBox="0 -960 960 960" 
        :width="iconSize">
    <path 
        :fill="iconColor"
        d="M840-480 671-241q-13 18-31 29.5T600-200H180q-25 0-42.5-17.5T120-260v-440q0-25 17.5-42.5T180-760h420q22 0 40 11.5t31 29.5l169 239Z"
    />
    </svg>
</template>

<script>
export default {
    props: {
        iconColor: '',
        iconSize: '',
    }
}
</script>