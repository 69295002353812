import { render, staticRenderFns } from "./ChatIcon.vue?vue&type=template&id=40aa9510&scoped=true&"
var script = {}
import style0 from "./ChatIcon.vue?vue&type=style&index=0&id=40aa9510&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40aa9510",
  null
  
)

export default component.exports