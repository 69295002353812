export const CREATE_ATTENDENCE = 'CREATE_ATTENDENCE';
export const EDIT_ATTENDENCE = 'EDIT_ATTENDENCE';
export const EDIT_ATTENDENCE_STATUS = 'EDIT_ATTENDENCE_STATUS';
export const DELETE_ATTENDENCE = 'DELETE_ATTENDENCE';
export const LIST_ATTENDENCES = 'LIST_ATTENDENCES';
export const LIST_FINISHED_ATTENDENCES = "LIST_FINISHED_ATTENDENCES";
export const LOAD_ATTENDENCE = 'LOAD_ATTENDENCE';
export const SET_LOADING_ATTENDENCES = 'SET_LOADING_ATTENDENCES';

export const CREATE_USER = 'CREATE_USER';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const LIST_USERS = 'LIST_USERS';
export const LOAD_USER = 'LOAD_USER';
export const SET_LOADING_USERS = 'SET_LOADING_USERS';

export const CREATE_ITEM = 'CREATE_ITEM';
export const EDIT_ITEM = 'EDIT_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const LIST_ITEMS = 'LIST_ITEMS';
export const LOAD_ITEM = 'LOAD_ITEM';
export const SET_LOADING_ITEMS = 'SET_LOADING_ITEMS';

export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const EDIT_PAYMENT = 'EDIT_PAYMENT';
export const DELETE_PAYMENT = 'DELETE_PAYMENT';
export const LIST_PAYMENTS = 'LIST_PAYMENTS';
export const LOAD_PAYMENT = 'LOAD_PAYMENT';
export const SET_LOADING_PAYMENTS = 'SET_LOADING_PAYMENTS';

export const CREATE_HISTORIC = 'CREATE_HISTORIC';
export const EDIT_HISTORIC = 'EDIT_HISTORIC';
export const DELETE_HISTORIC = 'DELETE_HISTORIC';
export const LIST_HISTORICS = 'LIST_HISTORICS';
export const LOAD_HISTORIC = 'LOAD_HISTORIC';
export const SET_LOADING_HISTORICS = 'SET_LOADING_HISTORICS';

export const CREATE_STATUS = 'CREATE_STATUS';
export const EDIT_STATUS = 'EDIT_STATUS';
export const DELETE_STATUS = 'DELETE_STATUS';
export const LIST_STATUSES = 'LIST_STATUSES';
export const LOAD_STATUS = 'LOAD_STATUS';
export const SET_LOADING_STATUSES = 'SET_LOADING_STATUSES';

export const CREATE_COMMENTARY = 'CREATE_COMMENTARY';
export const EDIT_COMMENTARY = 'EDIT_COMMENTARY';
export const DELETE_COMMENTARY = 'DELETE_COMMENTARY';
export const LIST_COMMENTARIES = 'LIST_COMMENTARIES';
export const LOAD_COMMENTARY = 'LOAD_COMMENTARY';
export const SET_LOADING_COMMENTARIES = 'SET_LOADING_COMMENTARIES';

export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export const EDIT_SCHEDULE = 'EDIT_SCHEDULE';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
export const LIST_SCHEDULES = 'LIST_SCHEDULES';
export const LOAD_SCHEDULE = 'LOAD_SCHEDULE';
export const SET_LOADING_SCHEDULES = 'SET_LOADING_SCHEDULES';

export const CREATE_FILE = 'CREATE_FILE';
export const EDIT_FILE = 'EDIT_FILE';
export const DELETE_FILE = 'DELETE_FILE';
export const LIST_FILES = 'LIST_FILES';
export const LOAD_FILE = 'LOAD_FILE';
export const SET_LOADING_FILES = 'SET_LOADING_FILES';

export const CREATE_ENVIRONMENT = 'CREATE_ENVIRONMENT';
export const EDIT_ENVIRONMENT = 'EDIT_ENVIRONMENT';
export const DELETE_ENVIRONMENT = 'DELETE_ENVIRONMENT';
export const LIST_ENVIRONMENTS = 'LIST_ENVIRONMENTS';
export const LOAD_ENVIRONMENT = 'LOAD_ENVIRONMENT';
export const SET_LOADING_ENVIRONMENTS = 'SET_LOADING_ENVIRONMENTS';

export const CREATE_RESPONSIBLE = 'CREATE_RESPONSIBLE'

export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const RESET_UNREADCOUNT_CHAT = 'RESET_UNREADCOUNT_CHAT';
