import apiClient from '@/utils/api-client-without-base-url';

const suffixEndpoint = '/departments';

export default {
    getAll: () => apiClient.get(suffixEndpoint),

    createDepartment: data => {
        return apiClient.post(suffixEndpoint, data)
    },

    editDepartment: data => {
        return apiClient.put(`${suffixEndpoint}/${data.id}`, data)
    },

    deleteDepartment: id => {
        return apiClient.delete(`${suffixEndpoint}/${id}`)
    }
}