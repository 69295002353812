<template>
  <v-card
    class="context-menu"
    tabindex="0"
    outlined
    :loading="isLoading"
    v-click-outside-custom="handleClickOutside"
    :ripple="false"
    ref="contextMenu"
    v-show="isVisible"
    :style="{ top: position.top, left: position.left, 'z-index': '99999' }"
    @blur="$emit('close', $event)"
  >
    <slot>
      <div class="list-items">
        <div
          @click="$emit('onClickRow', { ...item, index })"
          class="item"
          v-for="(item, index) in items"
          :key="index"
        >
          <v-icon v-if="item.icon" class="mr-2">{{item.icon}}</v-icon>
          {{ item.description }}
        </div>
      </div>
    </slot>
  </v-card>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
    position: {
      type: Object,
      default() {
        return {
          top: '125px',
          left: '125px',
        };
      },
    },
  },
  methods: {
    handleClickOutside(){
      if(this.isVisible){
        this.$emit('close', true);
      }
    }
  },
  watch: {
    isVisible(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.contextMenu.focus();
        });
      }
    },
  },
};
</script>

<style>
.context-menu.v-card--link:focus:before{
  display: none!important;
}
</style>

<style scoped>
.context-menu:focus {
  outline: none;
}

.context-menu {
  background: var(--v-backgroundPanel-base);
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  border-top-left-radius: 0px;
  position: absolute;
  min-width: 128px;
  min-height: 64px;
}

.list-items {
  display: flex;
  flex-direction: column;
}

.list-items .item {
  width: 100%;
  padding: 12px 28px 12px 18px;
  font-size: 14px;
}

.list-items .item:hover {
  cursor: pointer;
  background-color: #dedede;
}
</style>