var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pageLoaded && _vm.pageNotOnLogin)?_c('v-app-bar',{staticClass:"menu",attrs:{"permanent":"","mini-variant":"","mini-variant-width":"30","touchless":"","app":"","elevate-on-scroll":"","flat":""}},[_c('div',{staticClass:"menu-header"},[_c('img',{staticClass:"logoImage",attrs:{"src":require("../../assets/img/logoNath.png"),"alt":""}})]),_c('v-list',{staticClass:"content item-list pl-10",attrs:{"dense":"","nav":"","height":"100%"}},_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.id,staticClass:"item",attrs:{"height":"100%"}},[_c('router-link',{class:{ 'disabled-route': !_vm.hasPermission(item.permission) },attrs:{"target":item.target,"to":item.routeName}},[_c('v-list-item-action',{staticClass:"item-action",attrs:{"height":"100%"}},[(item.routeName == _vm.$route.path ||
            item.routeName + '/' == _vm.$route.path
            )?_c('p',{staticStyle:{"color":"#177dff","font-weight":"600"}},[_vm._v(" "+_vm._s(item.title)+" ")]):[(item.routeName != _vm.$route.path &&
              _vm.hasPermission(item.permission)
              )?_c('p',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),(item.routeName != _vm.$route.path &&
              !_vm.hasPermission(item.permission)
              )?_c('p',[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()]],2)],1)],1)}),1),_c('v-spacer'),_c('div',{staticClass:"content right-items"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs }){return [_c('AppUserCard',_vm._b({},'AppUserCard',attrs,false))]}}],null,false,3781612452)},[_c('span',[_vm._v("Configurações do usuário")])])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }