<template>
    <svg xmlns="http://www.w3.org/2000/svg" :height="iconSize" viewBox="0 -960 960 960" :width="iconSize">
        <path :fill="iconColor" d="m560-120-57-57 144-143H200v-480h80v400h367L503-544l56-57 241 241-240 240Z" />
    </svg>
</template>
<script>
export default {
  props: {
    iconSize: '',
    iconColor: '',
  },
};
</script>
