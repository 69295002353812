import apiClient from '@/utils/api-client-without-base-url';

const suffixEndpoint = '/chatreasons';

export default {
    getAll: () => apiClient.get(suffixEndpoint),

    createFinishedChatReason: data => {
        return apiClient.post(suffixEndpoint, data);
    },
    editFinishedChatReason: data => {
        return apiClient.put(`${suffixEndpoint}/${data.id}`, data);
    },
    deleteFinishedChatReason: id => {
        return apiClient.delete(`${suffixEndpoint}/${id}`);
    }
}