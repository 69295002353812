// import colors from 'vuetify/es5/util/colors';

export default {
  light: {
    primary: '#007AFF',
    secondary: '#E1341E',
    info: '#69C9D6',
    success: '#8AEB94',
    red: '#FF9391',
    pink: '#EB8ADF',
    lightGreen: '#43EB9E',
    lightBlue: '#6CCDD0',
    lightBlueSecondary: '#8CBFDB',
    extraLightGreen: '#5ce0a5',
    extraRed: '#F65959',
    green: '#4BDA71',
    background: '#fff',
    backgroundPanel: '#EBEEF7',
    backgroundSecondary: '#ebeef7', // F5F7FA // F2F3F4 // e9ecf7
    backgroundHover: '#d7def5',
    backgroundTerciary: '#bdc4da',
    darkGray: '#323232',
    border: '#c0c0c0' // e9e9e9, d0d0d0
  },
  dark: {
    background: '#333',
    backgroundPanel: '#121212',
    backgroundSecondary: '#404040',
    backgroundTerciary: '#bdc4da',
    border: '#717171'
  }
};
