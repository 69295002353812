const Container = () => import('./../views/Container');

// const ProductPageDetails = () => import('./../views/ProductPageDetails');
const UserPageList = () => import('./../views/UserPageList');
// const ProductFormTaxDataEdit = () => import('./../forms/ProductFormTaxDataEdit');

export default [
  {
    path: '/usuarios',
    component: Container,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'user-list',
        component: UserPageList
      },
    ]
  }
];
