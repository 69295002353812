const Container = () => import("./../views/Container")

const Attendences = () => import('./../views/Attendences');

export default [
  {
    path: '/atendimentos',
    component: Container,
    props: true,
    meta: { permissions: 'view_attendence' },
    children: [
      {
        path: '', 
        name: 'atendimentos',
        component: Attendences
      },
    ]
  }
];
