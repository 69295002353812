import './utils/global-components';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store/index';
import vuetify from '@/plugins/vuetify';
import '@/plugins/print';
import '@/plugins/external-components';
import keyCodes from '@/plugins/key-codes';
import Notifications from 'vue-notification';

/* import VueSocketio from 'vue-socket.io';
import io from 'socket.io-client'; */

import moment from 'moment';
moment.locale('pt-br');

import baseUrlWsfrom from './utils/base-url-socket';

import { io } from 'socket.io-client';
import VueSocketIOExt from 'vue-socket.io-extended';
//Base URL webssockets

let url = ''
if (process.env.NODE_ENV == 'production')
  url = `wss://${baseUrlWsfrom}/socket` // <-- Quando commit colocar "wss"
else url = `ws://${baseUrlWsfrom}/socket`

const socket = io(url, {
  transports: ['websocket'],
  reconnection: true,
  query: {
    token: `Bearer ${window.localStorage.getItem('auth-token')}`
  },
  reconnectionDelay: 500,
  maxReconnectionAttempts: Infinity,
});

Vue.use(VueSocketIOExt, socket, {
  debug: true,
  store, // vuex store instance
  actionPrefix: 'SOCKET_', // keep prefix in uppercase
  eventToActionTransformer: (actionName) => actionName, // cancel camel case
});

Vue.use(Notifications);

import VueMask from 'v-mask';
Vue.use(VueMask);

import * as VeeValidate from 'vee-validate';

Vue.use(VeeValidate, {
  events: 'blur',
});

import { ValidationProvider } from 'vee-validate';
Vue.component('ValidationProvider', ValidationProvider);

import { ValidationObserver } from 'vee-validate';
Vue.component('ValidationObserver', ValidationObserver);

import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

extend('isEmailValid', { ...email, message: 'Email inválido' });
extend('isNameValid', (value) => {
  var re = /^[a-z ,.'-]+$/i;
  return re.test(value);
});
extend('isPasswordValid', (value) => {
  var re = /^[a-z ,.'-]+$/i;
  return re.test(value);
});
extend('isNotBlank', { ...required, message: 'Campo não pode ficar vazio' });

extend('isCPFValid', {
  validate: (value) => {
    value = value.replace(/\D/g, '');
    if (value.toString().length != 11 || /^(\d)\1{10}$/.test(value))
      return false;
    var result = true;
    [9, 10].forEach(function (j) {
      var soma = 0,
        r;
      value
        .split(/(?=)/)
        .splice(0, j)
        .forEach(function (e, i) {
          soma += parseInt(e) * (j + 2 - (i + 1));
        });
      r = soma % 11;
      r = r < 2 ? 0 : 11 - r;
      if (r != value.substring(j, j + 1)) result = false;
    });
    return result;
  },
  message: 'CPF inválido',
});

extend('isCEPValid', {
  validate: (value) => {
    value = value.replace(/^s+|s+$/g, '');
    var objER = /^[0-9]{2}.[0-9]{3}-[0-9]{3}$/;
    if (value.length > 0) {
      if (objER.test(value)) return true;
      else return false;
    } else return false;
  },
  message: 'CEP inválido',
});

extend('isContactValid', {
  validate: (value) => {
    var objER = /^\(\d{2}\) \d{4,5}-\d{4}$/gi;
    if (value.length > 0) {
      if (objER.test(value)) return true;
      else return false;
    } else return false;
  },
  message: 'Contato inválido',
});

import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

import { currency } from '@/utils/helpers/currency';

Vue.config.productionTip = false;
Vue.directive('click-outside-custom', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        if (vnode.context[binding.expression])
          vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

import cep from 'cep-promise';
import { validateBr } from 'js-brasil';

const globalMixin = {
  data() {
    return {
      tableItemsPerPage: 9,
    };
  },
  methods: {
    loadRowsPerPage() {
      // return parseInt(window.innerHeight / 65);
      this.tableItemsPerPage = this.getRowsPerPage();
      //console.log(this.tableItemsPerPage);
    },
    getAbbreviatedString(string, lettersCount) {
      const slices = string.trim().split(' ');

      if (slices.length == 1) return slices[0].slice(0, 1).toUpperCase();
      else {
        let letters = '';

        for (let i = 0; i < lettersCount; i++) {
          if (slices[i]) letters += slices[i].slice(0, 1).toUpperCase();
        }

        return letters;
      }
    },
    getRowsPerPage() {
      return Math.round(window.innerHeight / 72);
    },
    onResize() {
      this.loadRowsPerPage();
    },
    created() {
      this.loadRowsPerPage();
    },
    getKeyCodes: () => {
      return { ...keyCodes };
    },
    currencyBr(value) {
      return currency(value);
    },
    getAttendenceItemPrice(item) {
      return item.quantity * item.price * ((100 - item.discount) / 100);
    },
    userHasPermission(permission) {
      return store.state.config.loggedUser.role.name === 'Administrador'
        ? true
        : store.state.config.loggedUser.role.permissions.find(
          (el) => el.permission == permission
        )
          ? true
          : false;
    },
    datetimeBr(datetime, format = 'DD/MM/YYYY HH:mm') {
      return moment(datetime).format(format);
    },
    printLog(log) {
      //console.log(log);
    },
    dateBrToEn(data) {
      var dia = data.split('/')[0];
      var mes = data.split('/')[1];
      var ano = data.split('/')[2];

      return ano + '-' + ('0' + mes).slice(-2) + '-' + ('0' + dia).slice(-2);
      // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
    },
    async findAddressByZipCode(zipCode) {
      if (validateBr.cep(zipCode)) return await cep(zipCode);

      return null;
    },
  },
};

Vue.mixin(globalMixin);

const appInstance = new Vue({
  // components: { VueCsvImport  },
  sockets: {},
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

store.$vueInstance = appInstance;

import './plugins/resizable-table';
import { slice } from 'lodash';
