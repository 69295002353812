import {
    CREATE_SCHEDULE,
    EDIT_SCHEDULE,
    DELETE_SCHEDULE,
    LIST_SCHEDULES,
    SET_LOADING_SCHEDULES,
} from './mutation-types';

export default {
    [LIST_SCHEDULES]: (state, { schedules }) => {
        state.schedules = schedules;
        //console.log(state.schedules)
    },
    [EDIT_SCHEDULE]: (state, { schedule }) => {
        const indice = state.schedules.findIndex(t => t.id === schedule.id);
        state.schedules.splice(indice, 1, schedule);
    },
    [CREATE_SCHEDULE]: (state, { schedule }) => {
        state.schedules.push(schedule)
    },
}