// import { mapUsers, mapUser } from './../utils/helpers/users';

/* eslint eqeqeq: "off" */

export default {
  // users: state => mapUsers(state.users, state.categories),
  // getUser: state => id => mapUser(state.users.find(user => user.id == id)),
  getUser: state => id => state.users.find(user => user.id == id),
  getUserByLevel: state => level => state.users.find(user => user.level == level)
};
