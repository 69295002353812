export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const SEND_MESSAGE = 'SEND_MESSAGE'; 
export const GET_MESSAGES = 'GET_MESSAGES';
export const UPDATE_CHAT = 'UPDATE_CHAT';
export const ADD_CHAT = 'ADD_CHAT';
export const CHANGE_TAG = 'CHANGE_TAG';
export const GET_CONTACTS = 'GET_CONTACTS';
export const ALTER_FILTER_CONTACTS = 'ALTER_FILTER_CONTACTS';
export const SELECT_CONTACT = 'SELECT_CONTACT';
export const APPLY_FILTER = 'APPLY_FILTER';
export const DEFINE_APPLIED_FILTER = 'DEFINE_APPLIED_FILTER';
export const APPLY_FILTER_TAG = 'APPLY_FILTER_TAG';
export const APPLY_FILTER_DEPARTMENT = 'APPLY_FILTER_DEPARTMENT';
export const DEFINE_DEPARTMENTS_CHATS = 'DEFINE_DEPARTMENTS_CHATS';
export const FAIL_SEND_QUOTED_MSG = 'FAIL_SEND_QUOTED_MSG';
export const DEFINE_ISNEWCHAT = 'DEFINE_ISNEWCHAT';