<template>
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        :height="iconSize" 
        viewBox="0 -960 960 960" 
        :width="iconSize"
    >
    <path 
        :fill="iconColor"
        d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm56-97h489L578-473 446-302l-93-127-117 152Zm-56 97v-600 600Z"
    />
    </svg>
</template>

<script>
export default {
    props: {
        iconSize: '',
        iconColor: '',
    }
}
</script>