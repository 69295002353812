import * as types from './mutation-types';
import schedulesService from './../services/schedule-service';

export default {
    getSchedules: async ({ commit }) => {
        const response = await schedulesService.getAll()
        commit(types.LIST_SCHEDULES, { schedules: response.data })
    },
    editSchedule: async ({ commit }) => {
        const response = await schedulesService.edit(schedule)
        commit(types.EDIT_SCHEDULE, { schedule: response.data });
    },
    createSchedule: async ({ commit }, { schedule}) => {
        try {
            const response = await schedulesService.create(schedule)
            commit(types.CREATE_SCHEDULE, { schedule: response.data});
            return response.data;
        } catch (error) {
            //console.log(error)
        }
    },
}