<template>
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        :height="iconSize" 
        viewBox="0 -960 960 960" 
        :width="iconSize">
    <path
        :fill="iconColor"
        d="M294-242 70-466l43-43 181 181 43 43-43 43Zm170 0L240-466l43-43 181 181 384-384 43 43-427 427Zm0-170-43-43 257-257 43 43-257 257Z"
    />
    </svg>
</template>

<script>
export default {
    props: {
        iconColor: '',
        iconSize: '',
    }
}
</script>