import usersService from './../services/users-service';
import rolesService from './../services/users-roles-service';
import tagsService from './../services/tags-service';
import shortcutsService from './../services/shortcuts-service';
import finishedChatReasonsService from '../services/finished-chat-reasons-service';
import departmentsService from '../services/departments-service';
import menusChatBotService from './../services/menus-chat-bot-service';
import optionsChatBotService from '../services/options-chat-bot-service';

import * as types from './mutation-types';

export default {
  //START TAGS
  getTags: async ({ commit }) => {
    const response = await tagsService.getAll();
    commit(types.LIST_TAGS, { tags: response.data });
  },
  deleteTag: async ({ commit }, { tagId }) => {
    try {
      const response = tagsService.deleteTag(tagId);
      commit(types.DELETE_TAG, { tagId });
    } catch (error) {
      console.log(error);
    }
  },
  createTag: async ({ commit }, { tag }) => {
    try {
      const response = await tagsService.createTag(tag);
      commit(types.CREATE_TAG, { tag: response.data });
    } catch (error) {
      console.log(error);
    }
  },
  editTag: async ({ commit }, { tag }) => {
    try {
      const response = await tagsService.editTag(tag);
      commit(types.EDIT_TAG, { tag: response.data });
    } catch (error) {
      console.log(error);
    }
  },
  //END TAGS

  //START SHORTCUTS
  getShortcuts: async ({ commit }) => {
    const response = await shortcutsService.getAll();
    commit(types.LIST_SHORTCUTS, { shortcuts: response.data });
  },
  createShortcut: async ({ commit }, { shortcut }) => {
    try {
      const response = await shortcutsService.createShortcut(shortcut);
      commit(types.CREATE_SHORTCUT, { shortcut: response.data });
    } catch (error) {
      console.log(error);
    }
  },
  deleteShortcut: async ({ commit }, { shortcutId }) => {
    try {
      const response = await shortcutsService.deleteShortcut(shortcutId);
      commit(types.DELETE_SHORTCUT, { shortcutId });
    } catch (error) {
      console.log(error);
    }
  },
  editShortcut: async ({ commit }, { shortcut }) => {
    try {
      const response = await shortcutsService.editShortcut(shortcut);
      commit(types.EDIT_SHORTCUT, { shortcut: response.data });
    } catch (error) {
      console.log(error);
    }
  },
  //END SHORTCUTS

  //START CHATREASONS
  getChatReasons: async ({ commit }) => {
    const response = await finishedChatReasonsService.getAll();
    commit(types.LIST_CHATREASONS, { finishedChatReasons: response.data });
  },
  createFinishedChatReason: async ({ commit }, { finishedChatReason }) => {
    const response = await finishedChatReasonsService.createFinishedChatReason(finishedChatReason);
    commit(types.CREATE_CHATREASON, response.data);
  },
  editFinishedChatReason: async ({ commit }, { finishedChatReason }) => {
    const response = await finishedChatReasonsService.editFinishedChatReason(finishedChatReason);
    commit(types.EDIT_CHATREASON, response.data);

  },
  deleteFinishedChatReason: async ({ commit }, { finishedChatReasonId }) => {
    try {
      const response =
        await finishedChatReasonsService.deleteFinishedChatReason(
          finishedChatReasonId
        );
      commit(types.DELETE_CHATREASON, {
        finishedChatReasonId: finishedChatReasonId,
      });
    } catch (error) {
      console.log(error);
    }
  },
  //END CHATREASONS

  //START DEPARTMENTS
  getDepartments: async ({ commit }) => {
    const response = await departmentsService.getAll();
    commit(types.LIST_DEPARTMENTS, { departments: response.data });
  },
  createDepartment: async ({ commit }, { department }) => {
    const response = await departmentsService.createDepartment(department);
    commit(types.CREATE_DEPARTMENT, response.data);
  },
  editDepartment: async ({ commit }, { department }) => {
    const response = await departmentsService.editDepartment(department);
    commit(types.EDIT_DEPARTMENT, response.data);
  },
  deleteDepartment: async ({ commit }, { departmentId }) => {
    const response = await departmentsService.deleteDepartment(departmentId);
    commit(types.DELETE_DEPARTMENT, { departmentId: departmentId });
  },
  //END DEPARTMENTS

  //START USER
  alterStatusWhats: async ({ commit }, userId) => {
    const response = usersService.alterStatusWhats(userId);
    commit(types.ALTER_USER_STATUSWHATS, userId)
  },
  aleterChatBot: async ({ commit }, userId) => {
    const response = usersService.alterChatBot(userId);
    commit(types.ALTER_USER_CHATBOT, userId);
  },
  getUsers: async ({ commit }) => {
    const response = await usersService.getAll();
    const userId = window.localStorage.getItem('userId');
    const loggedUser = response.data.find((user) => +user.id == +userId);
    commit(types.EDIT_LOGGEDUSER, { loggedUser });

    const users = response.data.map((user) => {
      if (+user.id == +userId) {
        return {
          ...user,
          url: null,
          isTheLoggedUser: true,
        };
      } else {
        return { ...user };
      }
    });
    // const users = response.data.filter(user => +user.id != +userId);
    commit(types.LIST_USERS, { users });
  },
  createUser: async ({ commit }, { user, role }) => {
    try {
      const response = await usersService.create(user);

      commit(types.CREATE_USER, { user: response.data, role });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  editUser: async ({ commit }, { user }) => {
    try {
      const response = await usersService.edit(user);
      const newUser = response.data;
      commit(types.EDIT_USER, { user: newUser });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  deleteUser: async ({ commit }, { userId }) => {
    try {
      const response = await usersService.delete(userId);
      commit(types.DELETE_USER, { userId });
      return res.json('excluded');
    } catch (error) {
      console.log(error);
    }
  },
  //END USER

  //START MENUSCHATBOT
  getMenusChatBot: async ({ commit }) => {
    const response = await menusChatBotService.getAll();
    commit(types.LIST_MENUSCHATBOT, { menus: response.data });
  },
  createMenuChatBot: async ({ commit }, menuChatBot) => {
    const response = await menusChatBotService.createMenuChatBot(menuChatBot);
    commit(types.CREATE_MENUCHATBOT, response.data);
    return response.data.id
  },
  editMenuChatBot: async ({ commit }, menuChatBot) => {
    const response = await menusChatBotService.editMenuChatBot(menuChatBot);
    commit(types.EDIT_MENUCHATBOT, response.data);
    return response.data.id
  },
  changeWelcomeMenu: async ({ commit }, id) => {
    const response = await menusChatBotService.changeWelcomeMenu(id)
    commit(types.CHANGE_WELCOME_MENU, id);
  },
  deleteMenusChatBot: async ({ commit }, id) => {
    const response = await menusChatBotService.deleteMenuChatBot(id);
    commit(types.DELETE_MENUCHATBOT, id);
  },
  //END MENUSCHATBOT

  //START OPTIONSCHATBOT
  getOptionsChatBot: async ({ commit }) => {
    const response = await optionsChatBotService.getAll();
    commit(types.LIST_OPTIONSCHATBOT, { options: response.data });
  },
  createOptionChatBot: async ({ commit }, options) => {
    const response = await optionsChatBotService.createOptionChatBot(options);
    commit(types.CREATE_OPTIONCHATBOT, response.data);
  },
  editOptionsChatBot: async ({ commit }, options) => {
    const response = await optionsChatBotService.editOptionChatBot(options);
    commit(types.EDIT_OPTIONCHATBOT, response.data);
  },
  deleteOptionsChatBot: async ({ commit }, optionsIds) => {
    const response = await optionsChatBotService.deleteOptionChatBot(optionsIds);
    commit(types.DELETE_OPTIONCHATBOT, optionsIds);
  },
  //END OPTIONSCHATBOT

  editLoggedUser: async ({ commit }, { user }) => {
    commit(types.EDIT_LOGGEDUSER, { user });
  },
  getUserRoles: async ({ commit }) => {
    const response = await rolesService.getAll();
    commit(types.LIST_USERROLESS, { roles: response.data });
  },
  //GERAR QRCODE
  [`SOCKET_RECEIVE_QRCODE`]: ({ commit }, qrcode) => {
    commit(types.QRCODE_GENERATOR, qrcode);
  },
  //MENSAGEM QRCODE
  [`SOCKET_MESSAGE_QRCODE`]: ({ commit }, messageQrcode) => {
    console.log("messageQrcode: ", messageQrcode);
    commit(types.MESSAGE_QRCODE, messageQrcode);
  }
};
