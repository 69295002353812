import UsersService from './../services/users-service';

import * as types from './mutation-types';

export default {
  // users
  createUser: ({ commit, dispatch }, { user }) => {
    return new Promise((resolve, reject) => {
      UsersService.create(user)
        .then(response => {
          const { data } = response;
          commit(types.CREATE_USER, { user: data });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  editUser: async ({ commit }, { user }) => {
    const { data } = await UsersService.edit(user);
    //console.log(data);
    commit(types.EDIT_USER, { user: data });
  },
  deleteUser: async ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      UsersService.delete(id)
        .then(response => {
          commit(types.DELETE_USER, { id });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  // deleteArrayUsers: async ({ commit }, { ids }) => {
  //   await UsersService.delete(user.id);
  //   commit(types.DELETE_USER, { user });
  // },
  saveConfigSale: async ({ commit }, { config }) => {
    const { data } = await UsersService.saveConfigSale(config);
    commit(types.LOAD_LOGGED_USER, { user: data });
  },
  getUsers: async ({ commit }) => {
    try {
      const response = await UsersService.getAll();
      // //console.log(response.data.filter(user => user.promotion));

      const userId = window.localStorage.getItem('userId');

      const loggedUser = response.data.find(user => +user.id == +userId);

      commit(types.LOAD_LOGGED_USER, { user: loggedUser });

      const users = response.data.map(user => {
        if (+user.id == +userId) {
          return {
            ...user,
            isTheLoggedUser: true
          };
        } else {
          return { ...user };
        }
      });
      // const users = response.data.filter(user => +user.id != +userId);

      commit(types.LIST_USERS, { users });
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  }
};
