<template>
    <svg 
    xmlns="http://www.w3.org/2000/svg" 
    :height="iconSize" 
    viewBox="0 -960 960 960" 
    :width="iconSize">
    <path 
    :fill="iconColor"
    d="M450-400h60v-130h130v-60H510v-130h-60v130H320v60h130v130ZM80-80v-740q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H240L80-80Zm134-220h606v-520H140v600l74-80Zm-74 0v-520 520Z"/>
    </svg>
</template>

<script>
export default {
    props :{
        iconColor : '',
        iconSize: ''
    }
}
</script>