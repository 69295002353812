export const CREATE_CLIENT = 'CREATE_CLIENT';
export const EDIT_CLIENT = 'EDIT_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const LIST_CLIENTS = 'LIST_CLIENTS';
export const LOAD_CLIENT = 'LOAD_CLIENT';

export const SET_LOADING_CLIENTS = 'SET_LOADING_CLIENTS';
export const CREATE_CLIENT_ADDRESS = 'CREATE_CLIENT_ADDRESS';
export const EDIT_CLIENT_ADDRESS = 'EDIT_CLIENT_ADDRESS';
export const DELETE_CLIENT_ADDRESS = 'DELETE_CLIENT_ADDRESS';
export const LIST_CLIENTS_ADDRESSES = 'LIST_CLIENTS_ADDRESSES';
export const LOAD_CLIENT_ADDRESS = 'LOAD_CLIENT_ADDRESS';
export const SET_LOADING_CLIENTS_ADDRESSES = 'SET_LOADING_CLIENTS_ADDRESSES';
