const Container = () => import('./../views/Container');

// const ProductPageDetails = () => import('./../views/ProductPageDetails');
const Calendar = () => import('./../views/Calendar');
// const ProductFormTaxDataEdit = () => import('./../forms/ProductFormTaxDataEdit');

export default [
  {
    path: '/agenda',
    component: Container,
    props: true,
    meta: { permissions: 'view_schedule' },
    children: [
      {
        path: '',
        name: 'agenda',
        component: Calendar
      },
    ]
  }
];
