<template>
  <div class="fill-height">
    <AppMenu v-if="isAppMenuVisible && $route.path != '/chat'" />
    <v-container class="main-painel">
      <v-layout class="fill-height" fluid v-resize="onResize">
        <v-flex xs12 id="content-page"> <!--class="tw-flex tw-items-center tw-justify-center"-->
          <slot></slot>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState(['isAppMenuVisible']),
  },
  
  methods: {
    selectedRoute(route) {
      const thisRoute = this.$route.path.split('/');
      thisRoute[1] = '/' + thisRoute[1];

      return thisRoute[1].includes(route);
    },
  },
};
</script>

<style>
.main-painel {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  display: inline-block;
  /* padding: 10px 0px; */
  padding: 0px;
  background: var(--v-backgroundPanel-base);
}

.main-painel::-webkit-scrollbar-thumb {
  background-color: var(--v-primary);
  border-radius: 3px;
}

.main-painel::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.main-painel:hover::-webkit-scrollbar-thumb {
  -webkit-transition: 1s;
  transition: 1s;
}

.theme--light .main-painel:hover::-webkit-scrollbar-thumb {
  background-color: #bbbbbb !important;
}

.theme--dark .main-painel:hover::-webkit-scrollbar-thumb {
  background-color: #424242 !important;
}
</style>
