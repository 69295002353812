<template>
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        :height="iconSize" 
        viewBox="0 -960 960 960" 
        :width="iconSize">
    <path 
        :fill="iconColor"
        d="M450-450H200v-60h250v-250h60v250h250v60H510v250h-60v-250Z"/>
    </svg>
</template>
<script>
export default {
    props: {
        iconColor: '',
        iconSize: '',
    }
}
</script>