<template>
  <v-dialog max-width="700" v-model="show" @click:outside="closeDialog">
    <v-card>
      <v-card-title>{{message}}</v-card-title>
      <v-card-actions>
          <v-spacer></v-spacer>
        <v-btn dark depressed color="blue" @click="$emit('confirmed')">Sim</v-btn>
        <v-btn text color="blue" @click="closeDialog">Não</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: { value: Boolean, message: String},
  methods: {
    closeDialog() {
      this.$emit('close', false);
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>