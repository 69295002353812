import * as types from './mutation-types';

export default {
  // users
  [types.CREATE_USER]: (state, { user }) => {
    state.users.push(user);
  },
  [types.EDIT_USER]: (state, { user }) => {
    const index = state.users.findIndex(t => t.id === user.id);
    state.users.splice(index, 1, user);
  },
  [types.DELETE_USER]: (state, { id }) => {
    const index = state.users.findIndex(t => t.id === id);
    state.users.splice(index, 1);
  },
  [types.LIST_USERS]: (state, { users }) => {
    state.users = users;
  },
  [types.LOAD_LOGGED_USER]: (state, { user }) => {
    state.loggedUser = user;
  }
};
