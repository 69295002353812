export default{
    getAttendences: state => state.attendences,
    getfinishedAttendences: state => state.finishedAttendences,
    getAttendence: state => id => state.attendences.find(atten => atten.id == id),
    getUser: state => id => state.users.find(atten => atten.id == id),
    getAttendenceItem: state => id =>  state.attendenceItems.find(atten => atten.id == id),
    getAttendencePayment: state => id =>  state.ItemPayments.find(atten => atten.id == id),
    getAttendenceStatus: state => id =>  state.statuses.find(status => status.id == id),
    getStatusIdByName: state => name =>  state.statuses.find(status => status.name == name).id,
    getStatusByName: state => name =>  state.statuses.find(status => status.name == name),
    getStatusByPlace: state => place =>  state.statuses.find(status => status.place == place),
    getAttendenceByStart: state => time => state.attendences.filter(atten => atten.startTime > time),
    getAttendenceByEnd: state => time => state.attendences.filter(atten => atten.endTime < time),
    getAttendencesByClient: state => clientId => state.attendences.filter(atten => atten.client.id == clientId),
    getEnvironment: state => id => state.environments.find(atten => atten.id == id),
    attendenceHasSchedule: state => (atteId, schedId) => state.attendences.find(atten => atten.id == atteId).schedules.find(schedule => schedule.statusId == schedId)
}