import Vue from 'vue';
import VueRouter from 'vue-router';

import authRoutes from '@/modules/auth/router/';
import configurationsRoutes from '@/modules/configurations/router/';
import clientsRoutes from '@/modules/clients/router/';
import calendarRoutes from '@/modules/calendar/router/';
import usersRoutes from '@/modules/users/router';
import finishedRoutes from '@/modules/finished/router'
import attendencesRoutes from '@/modules/attendences/router'
import chatRoutes from '@/modules/Chat/router'

import authMiddleware from '@/modules/auth/router/auth-middleware';

import store from '../store';

Vue.use(VueRouter);

const routes = [
  ...authRoutes,
  ...configurationsRoutes,
  ...clientsRoutes,
  ...usersRoutes,
  ...calendarRoutes,
  ...finishedRoutes,
  ...attendencesRoutes,
  ...chatRoutes,
  {
    path: '',
    redirect: '/atendimentos'
  }
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

router.beforeEach(authMiddleware);

router.beforeEach(async (to, from, next) => {
  if (!(store &&
    store.state &&
    store.state.config &&
    store.state.config.loggedUser &&
    store.state.config.loggedUser.role &&
    store.state.config.loggedUser.role.permissions)) {
    try {
      await store.dispatch('config/getUsers')
    } catch (error) {
      console.log(error)
    }
  }
  try {
    if (to.matched.some(record => record.meta.permissions)) {
      const permissionName = to.matched.find(record => record.meta.permissions).meta.permissions
      const configStore = router.app.$store.state.config
      const loggedUser = configStore.loggedUser
      const has = loggedUser.role.permissions.find(el => {
        return el.permission == permissionName ? true : false
      })
      if (has) {
        next();

      } else {
      }
    }
    else {
      next();
    }
  } catch (error) {
    console.log(error)
  }
});


export default router;
