<template>
  <v-dialog max-width="900" v-model="show" @click:outside="closeDialog" scrollable>
    <v-card class="create-user" :loading="loading">
      <v-card-title class="mb-5"
        >
        <template v-if="!selectedUser">

        Novo Usuário
        </template>
        <template v-else>

        Editar usuário
        </template>

        <v-spacer></v-spacer>
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <ValidationObserver v-slot="v" ref="observer">
          <form @submit.prevent="v.handleSubmit(onSubmit)">
            <v-row>
              <v-col cols="7">
                <v-card-subtitle class="pl-0 pb-2 pt-0">Nome*</v-card-subtitle>
                <ValidationProvider rules="isNotBlank|isNameValid" v-slot="v">
                  <v-text-field
                    dense
                    outlined
                    hide-details="auto"
                    v-model="user.name"
                    :disabled="!hasPermission"
                  ></v-text-field>
                  <span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </v-col>
              <v-col cols="5">
                <v-card-subtitle class="pl-0 pb-2 pt-0">E-mail*</v-card-subtitle>
                <ValidationProvider rules="isNotBlank|isEmailValid" v-slot="v">
                  <v-text-field
                    dense
                    outlined
                    hide-details="auto"
                    v-model="user.email"
                    :disabled="!hasPermission"
                  ></v-text-field>
                  <span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="7">
                <v-card-subtitle class="pl-0 pb-2 pt-0">Nível*</v-card-subtitle>
                <v-select
                  dense
                  outlined
                  hide-details
                  v-model="user.roleId"
                  :items="userRoles"
                  item-text="name"
                  item-value="id"
                  :disabled="!hasPermission"
                ></v-select>
                <ValidationProvider rules="isNotBlank" v-slot="v">
                  <v-text-field
                    v-model="user.roleId"
                    class="form-control disabled"
                    style="display: none"
                    :disabled="!hasPermission"
                  ></v-text-field>
                  <span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </v-col>
              <v-col cols="5">
                <v-card-subtitle class="pl-0 pb-2 pt-0">Senha*</v-card-subtitle>
                <ValidationProvider rules="isNotBlank" v-if="!selectedUser" v-slot="v">
                  <v-text-field
                    dense
                    outlined
                    hide-details="auto"
                    v-model="user.password"
                  ></v-text-field>
                  <span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
                <v-text-field
                    dense
                    v-else
                    outlined
                    hide-details="auto"
                    v-model="user.password"
                  ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card-subtitle class="pl-0 pb-2 pt-0">Foto</v-card-subtitle>
                <div v-if="imageFile.length == 0 && !user.photoURL">
                  <div
                    :class="['dropZone', dragging ? 'dropZone-over' : '']"
                    @dragenter="dragging = true"
                    @dragleave="dragging = false"
                  >
                    <div class="dropZone-info" @drag="onChange" >
                      <cloud-upload-icon-grey width="40" height="40" style="margin-left: 50%;"/>
                      <div>
                        <span class="dropZone-title"
                          >Clique ou arraste para adicionar a foto</span
                        >
                      </div>
                      <!-- <div class="dropZone-upload-limit-info">
          <div>extension support: txt</div>
          <div>maximum file size: 5 MB</div>
        </div> -->
                    </div>
                    <input type="file" @change="onChange" />
                  </div>
                </div>
                <div v-else class="dropZone-uploaded">
                  <div class="dropZone-uploaded-info" v-if="!user.photoURL">
                    <div v-for="(fi, i) in imageFile" :key="i" class="tw-ml-20">
                      <div class="file-description">
                        <span>
                          {{ fi.name }}
                        </span>
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary removeFile"
                        @click="removeFile(i)"
                      >
                        Remover arquivo
                      </button>
                    </div>
                  </div>
                  <div class="dropZone-uploaded-info" v-else>
                    <div style="margin-left: 42%;">
                      <div
                        @click="downloadPhoto"
                        class="file-description"
                        v-if="user.photoKey"
                      >
                        <UserPhoto size="64" width="64" height="64" :user="user" 
                        style="padding: 0px;"
                        />
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary removeFile"
                        @click="removeUserPhotoKey"
                      >
                        Remover arquivo
                      </button>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions class="pt-7 pb-8">
        <v-btn
          text
          v-if="selectedUser && userHasPermission('delete_user') && !(selectedUser.id === loggedUser.id)"
          @click="modalDelete = true"
          ><v-icon>la-trash-alt</v-icon>Excluir usuário</v-btn
        >

        <v-spacer></v-spacer>
        <v-btn dark depressed color="blue" @click="validateForm()"
          >Confirmar</v-btn
        >
        <v-btn text color="blue" @click="$emit('close')">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmationModal
      v-if="modalDelete"
      v-model="modalDelete"
      message="Você quer mesmo deletar este usuário?"
      @close="modalDelete = false"
      @confirmed="excludeUser"
    />
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState: mapStateConfig } = createNamespacedHelpers('config');
const { mapActions: mapActionsAttendences } =
  createNamespacedHelpers('attendences');

import ConfirmationModal from '../../../components/utils/ConfirmationModal.vue';

import UserPhoto from '../../../components/utils/UserPhoto.vue';

export default {
  props: { value: Boolean, selectedUser: Object, hasPermission: Boolean },
  components: {
    ConfirmationModal,
    UserPhoto
  },
  data() {
    return {
      modalDelete: false,
      loading: false,
      user: {},
      imageFile: [],
      dragging: false,
    };
  },
  methods: {
    ...mapActionsAttendences(['downloadFile']),
    async excludeUser(){
      this.loading = true
      this.$emit('excludeUser', this.selectedUser.id)
    },
    downloadPhoto() {
      const url = this.user.photoURL;
      let link = document.createElement('a');
      link.href = url;
      link.download = this.user.photoKey;
      link.click();
    },
    removeUserPhotoKey() {
      this.user.photoKey = null;
      this.user.photoURL = null;
    },
    onSubmit() {
    },
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.dragging = false;
        return;
      }
      this.createFile(files);
      if (this.selectedUser) {
        const key = 'users/' + this.selectedUser.id + '/photo';
        this.user.photoKey = key;
      }
    },
    createFile(files) {
      files = Array.from(files);
      files.forEach((file) => {
        if (!file.type.match('image.*')) {
          this.dragging = false;
          return;
        }
        if (file.size > 100000000) {
          this.$notify({
            group: 'error',
            title: 'Tamanho de arquivo maior que 100MB.',
            type: 'warn',
            // text: 'Hello user! This is a notification!',
          });
          this.dragging = false;
          return;
        }
        this.imageFile = Array.from(files);
      });
      // this.file.forEach()
      this.dragging = false;
    },
    removeFile(i) {
      this.imageFile.splice(i, 1);
      if (this.selectedUser) {
        this.user.photoKey = null;
        // delete this.user.photoKey;
      }
    },
    async validateForm() {
      const isValid = await this.$refs.observer.validate();
      if (this.$refs.observer._data.flags.valid) {
        this.saveNewClient();
      }
    },
    closeDialog() {
      this.$emit('close', false);
    },
    saveNewClient() {
      this.loading = true
      const role = this.userRoles.find((el) => el.id == this.user.roleId);
      if (!this.user.id) {
        this.$emit('saveNewUser', {
          user: this.user,
          role,
          imageFile: this.imageFile,
        });
      } else {
        this.$emit('editUser', {
          user: this.user,
          role,
          imageFile: this.imageFile,
        });
      }
    },
  },
  computed: {
    ...mapStateConfig(['userRoles', 'loggedUser']),
    extension() {
      return this.filePage[this.choosenTab]
        ? this.filePage[this.choosenTab].name.split('.').pop()
        : '';
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  async mounted() {
    if (this.selectedUser) {
      this.user = { ...this.selectedUser };
      this.user.password = null
      if (this.selectedUser.photoKey) {
        this.user.photoURL = await this.downloadFile({
          key: this.selectedUser.photoKey,
        });
      }
    }
  },
};
</script>

<style>
.create-user .dropZone {
  width: 100%;
  height: 100px;
  position: relative;
  border: 2px dashed #b7c3da;
  border-radius: 12px;
}

.create-user .dropZone:hover {
  border: 2px solid #2e94c4;
}

.create-user .dropZone:hover .dropZone-title {
  color: #1975a0;
}

.create-user .dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.create-user .dropZone-title {
  color: #000000;
}

.create-user .dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.create-user .dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.create-user .dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.create-user .dropZone-uploaded {
  width: 100%;
  height: 100px;
  border: 2px dashed #b7c3da;
  border-radius: 12px;
}

.create-user .dropZone-uploaded-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #a8a8a8;
  height: 100%;
}

.create-user .dropZone-uploaded-info > div {
  padding-right: 30px;
  padding-left: 30px;
}

.create-user .file-description {
  display: flex;
  justify-content: center;
}

.create-user .removeFile {
  width: fit-content;
}
/* .v-card__subtitle{
  margin-bottom: -20px;
}
.col.col-12 .v-card__subtitle{
  margin-bottom: 0px;
} */
</style>
