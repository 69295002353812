let baseURLWS;
if (process.env.NODE_ENV == 'development') {
  //Your IP or localhost
  baseURLWS = 'localhost:3000';
} else {
  //Prod
  baseURLWS = process.env.WSS_URL;
}

export default baseURLWS;
