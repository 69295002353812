import clientsService from './../services/clients-service';
import clientsAddressesService from './../services/clients-addresses-service';

import * as types from './mutation-types';

export default {
  createClient: async ({ commit }, { client }) => {
    const response = await clientsService.create(client)
    commit(types.CREATE_CLIENT, { client: response.data });
    return response.data;
  },
  getClients: async ({ commit }) => {
    const response = await clientsService.getAll()
    commit(types.LIST_CLIENTS, { clients: response.data })
  },
  editClient: async ({commit}, {client}) => {
  let response = await clientsService.edit(client)
  response = response.data
  response.clientAddress = client.clientAddress
  commit(types.EDIT_CLIENT, { client: response });
  return response
  },
  createClientAddress: async ({ commit }, { clientAddress }) => {
    const response = await clientsAddressesService.create(clientAddress)
    commit(types.CREATE_CLIENT_ADDRESS, { clientAddress: response.data });
    return response.data;
  },
  editClientAddress: async ({ commit }, { clientAddress }) => {
      const response = await clientsAddressesService.edit(clientAddress);
      commit(types.EDIT_CLIENT_ADDRESS, { clientAddress: response.data });
      return response.data
    },
  // createClient: ({ commit }, { client }) => {
  //   return new Promise((resolve, reject) => {
  //     ClientsService.create(client)
  //       .then(response => {
  //         commit(types.CREATE_CLIENT, { client: response.data });
  //         resolve(response);
  //       })
  //       .catch(error => reject(error));
  //   });
  // },
  // editClient: async ({ commit }, { client }) => {
  //   const response = await ClientsService.edit(client);
  //   commit(types.EDIT_CLIENT, { client: response.data });
  // },
  // deleteClient: async ({ commit }, { client }) => {
  //   // //console.log(client.id);
  //   await ClientsService.delete(client.id);
  //   commit(types.DELETE_CLIENT, { client });
  // },
  // getClient: async ({ commit }, id) => {
  //   try {
  //     const response = await ClientsService.get(id);
  //     commit(types.LOAD_CLIENT, { client: response.data });
  //   } catch (erro) {
  //     // alert(erro);
  //     // commit(types.SETAR_ERRO, { erro });
  //   }
  // },
  // getClients: async ({ commit }) => {
  //   commit(types.SET_LOADING_CLIENTS, { isLoadingClients: true });

  //   try {
  //     const response = await ClientsService.getAll();
  //     commit(types.LIST_CLIENTS, { clients: response.data });
  //   } catch (erro) {
  //     // alert(erro);
  //     // commit(types.SETAR_ERRO, { erro });
  //   }

  //   commit(types.SET_LOADING_CLIENTS, { isLoadingClients: false });
  // }
};
