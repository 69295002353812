const Container = () => import('./../views/Container');

const ClientPageDetails = () => import('./../views/ClientPageDetails');
const ClientPageList = () => import('./../views/ClientPageList');

export default [
  {
    path: '/clientes',
    component: Container,
    meta: { permissions: 'view_client' },
    children: [
      {
        path: '',
        name: 'client-list',
        component: ClientPageList
      },
      {
        path: 'cliente/:id/',
        name: 'client-details',
        component: ClientPageDetails
      }
    ]
  }
];
