import * as types from './mutation-types';

//options service
import optionsChatBotService from '../services/options-chat-bot-service';

export default {
  //USERS
  [types.EDIT_LOGGEDUSER]: (state, { loggedUser }) => {
    state.loggedUser = loggedUser;
  },
  [types.ALTER_USER_CHATBOT]: (state, id) => {
    state.users.map((item) => {
      if (item.id == id) {
        item.isChatBotActive = !item.isChatBotActive;
      }
    })
  },
  [types.ALTER_USER_STATUSWHATS]: (state, id) => {
    state.users.map((item) => {
      if (item.id == id) {
        item.statusWhats = !item.statusWhats;
      }
    })
  },
  [types.EDIT_USER]: (state, { user }) => {
    const index = state.users.findIndex(el => el.id == user.id)
    state.users.splice(index, 1, user)
  },
  [types.LIST_USERS]: (state, { users }) => {
    state.users = users;
  },
  [types.LIST_USERROLESS]: (state, { roles }) => {
    state.userRoles = roles;
  },
  [types.CREATE_USER]: (state, { user, role }) => {
    user.role = role
    delete user.token
    state.users.push(user);
  },
  [types.DELETE_USER]: (state, { userId }) => {
    const index = state.users.findIndex(t => t.id === userId);
    state.users.splice(index, 1);
  },
  //TAGS
  [types.LIST_TAGS]: (state, { tags }) => {
    state.tags = tags;
  },
  [types.DELETE_TAG]: (state, { tagId }) => {
    const index = state.tags.findIndex(t => t.id === tagId);
    state.tags.splice(index, 1);
  },
  [types.CREATE_TAG]: (state, { tag }) => {
    state.tags.push(tag);
  },
  [types.EDIT_TAG]: (state, { tag }) => {
    const index = state.tags.findIndex(t => t.id === tag.id);
    state.tags.splice(index, 1, tag);
  },
  //SHORTCUTS
  [types.LIST_SHORTCUTS]: (state, { shortcuts }) => {
    state.shortcuts = shortcuts;
  },
  [types.CREATE_SHORTCUT]: (state, { shortcut }) => {
    state.shortcuts.push(shortcut)
  },
  [types.DELETE_SHORTCUT]: (state, { shortcutId }) => {
    const index = state.shortcuts.findIndex(t => t.id === shortcutId);
    state.shortcuts.splice(index, 1);
  },
  [types.EDIT_SHORTCUT]: (state, { shortcut }) => {
    const index = state.shortcuts.findIndex(t => t.id === shortcut.id);
    state.shortcuts.splice(index, 1, shortcut)
  },
  //CHATREASONS
  [types.LIST_CHATREASONS]: (state, { finishedChatReasons }) => {
    state.finishedChatReasons = finishedChatReasons;
  },
  [types.CREATE_CHATREASON]: (state, finishedChatReason) => {
    state.finishedChatReasons.push(finishedChatReason);
  },
  [types.EDIT_CHATREASON]: (state, finishedChatReason) => {
    const index = state.finishedChatReasons.findIndex(t => t.id === finishedChatReason.id);
    state.finishedChatReasons.splice(index, 1, finishedChatReason);
  },
  [types.DELETE_CHATREASON]: (state, { finishedChatReasonId }) => {
    let menusIds = [];
    const index = state.finishedChatReasons.findIndex(t => t.id === finishedChatReasonId);
    state.finishedChatReasons.splice(index, 1);
    const excludedItems = state.optionsChatBot.filter((item) => item.finishedTransferId == finishedChatReasonId);
    const newArr = state.optionsChatBot.filter((item) => item.finishedTransferId != finishedChatReasonId);
    state.optionsChatBot = newArr;
    excludedItems.forEach((item, index) => {
      if (index == 0) {
        menusIds.push(item.menuId);
      } else {
        menusIds.includes(item.menuId) ? null : menusIds.push(item.menuId);
      }
    });
    menusIds.forEach((item) => {
      let options = state.optionsChatBot.filter((el) => el.menuId == item);
      options.map((item, index) => {
        item.number = index + 1;
      });
      state.optionsChatBot.map((item) => {
        const op = options.find((el) => el.id == item.id);
        let arr = [op];
        optionsChatBotService.editOptionChatBot(arr);
        item = op;
      });
    });
  },
  //DEPARTMENTS
  [types.LIST_DEPARTMENTS]: (state, { departments }) => {
    state.departments = departments;
  },
  [types.CREATE_DEPARTMENT]: (state, department) => {
    let newDepartment = department.department;
    const users = department.users;
    let arr = [];
    users.forEach((item) => {
      arr.push(state.users.find((el) => el.id == item.userId));
    })
    newDepartment.users = arr;
    state.departments.push(newDepartment);
  },
  [types.EDIT_DEPARTMENT]: (state, department) => {
    let newDepartment = department.department;
    const users = department.users;
    let arr = [];
    users.forEach((item) => {
      arr.push(state.users.find((el) => el.id == item.userId));
    })
    newDepartment.users = arr;
    state.departments = state.departments.map((item) => {
      if(item.id == newDepartment.id){
        return item = newDepartment;
      }else{
        return item;
      }
    })
  },
  [types.DELETE_DEPARTMENT]: (state, { departmentId }) => {
    let menusIds = [];
    const index = state.departments.findIndex(t => t.id === departmentId);
    state.departments.splice(index, 1);
    const excludedItems = state.optionsChatBot.filter((item) => item.departmentTransferId == departmentId);
    const newArr = state.optionsChatBot.filter((item) => item.departmentTransferId != departmentId);
    state.optionsChatBot = newArr;
    excludedItems.forEach((item, index) => {
      if (index == 0) {
        menusIds.push(item.menuId);
      } else {
        menusIds.includes(item.menuId) ? null : menusIds.push(item.menuId);
      }
    });
    menusIds.forEach((item) => {
      let options = state.optionsChatBot.filter((el) => el.menuId == item);
      options.map((item, index) => {
        item.number = index + 1;
      });
      state.optionsChatBot.map((item) => {
        const op = options.find((el) => el.id == item.id);
        let arr = [op];
        optionsChatBotService.editOptionChatBot(arr);
        item = op;
      });
    });


  },
  //QRCODE
  [types.QRCODE_GENERATOR]: (state, qrcode) => {
    state.qrcode = qrcode;
  },
  [types.MESSAGE_QRCODE]: (state, messageQrcode) => {
    state.messageQrcode = messageQrcode;
  },
  //MENUS CHAT BOT
  [types.LIST_MENUSCHATBOT]: (state, { menus }) => {
    state.menusChatBot = menus;
  },
  [types.CREATE_MENUCHATBOT]: (state, menuChatBot) => {
    state.menusChatBot.push(menuChatBot);
  },
  [types.EDIT_MENUCHATBOT]: (state, menuChatBot) => {
    state.menusChatBot.map((item) => {
      if (item.id == menuChatBot.id) {
        item = menuChatBot;
      }
    })
  },
  [types.DELETE_MENUCHATBOT]: (state, id) => {
    let menusIds = [];
    const index = state.menusChatBot.findIndex((item) => item.id == id);
    state.menusChatBot.splice(index, 1);
    const excludedItems = state.optionsChatBot.filter((item) => item.menuTransferId == id);
    const newArr = state.optionsChatBot.filter((item) => item.menuTransferId != id);
    state.optionsChatBot = newArr;
    excludedItems.forEach((item, index) => {
      if (index == 0) {
        menusIds.push(item.menuId);
      } else {
        menusIds.includes(item.menuId) ? null : menusIds.push(item.menuId);
      }
    });
    menusIds.forEach((item) => {
      let options = state.optionsChatBot.filter((el) => el.menuId == item);
      options.map((item, index) => {
        item.number = index + 1;
      });
      state.optionsChatBot.map((item) => {
        const op = options.find((el) => el.id == item.id);
        let arr = [op];
        optionsChatBotService.editOptionChatBot(arr);
        item = op;
      });
    });
  },

  [types.CHANGE_WELCOME_MENU]: (state, id) => {
    state.menusChatBot.map((item) => {
      if (item.isWelcomeMenu) {
        item.isWelcomeMenu = !item.isWelcomeMenu;
        menuId = item.id;
      } if (item.id == id) {
        item.isWelcomeMenu = true;
      }
    })
    return id
  },

  //OPTIONS CHAT BOT
  [types.LIST_OPTIONSCHATBOT]: (state, { options }) => {
    state.optionsChatBot = options;
  },
  [types.CREATE_OPTIONCHATBOT]: (state, options) => {
    state.optionsChatBot = state.optionsChatBot.concat(options);
  },
  [types.EDIT_OPTIONCHATBOT]: (state, options) => {
    let newArr = state.optionsChatBot.filter((item) => item.menuId != options[0].menuId);
    state.optionsChatBot = newArr.concat(options);
  },
  [types.DELETE_OPTIONCHATBOT]: (state, optionsIds) => {
    const newArr = state.optionsChatBot.filter((item) => !optionsIds.includes(item.id));
    state.optionsChatBot = newArr;
  },
};