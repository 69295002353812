<template>
  <div>
    <label :for="imageInputLabel" v-if="!photoSelected" class="hover-pointer">
      <v-card
        outlined
        width="120px"
        color="backgroundSecondary"
        class="pa-2 mt-2 text-center"
      >
        <CameraIcon />
        {{ addButtonLabel }}
      </v-card>
    </label>

    <v-badge color="white" v-else>
      <template v-slot:badge>
        <v-btn xs-small icon color="gray" @click="removeFile">
          <v-icon>la-close</v-icon>
        </v-btn>
      </template>
      <label :for="imageInputLabel" class="hover-pointer">
        <v-hover v-slot:default="{ hover }">
          <v-img
            :src="photoSelectedWithoutCache"
            @error="$emit('errorLoad')"
            width="120px"
            class="align-center mt-2"
            :class="{ 'opacity-low': hover }"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
            <span :class="{ 'opacity-high': hover }" v-show="hover"
              ><div class="d-flex justify-center text-center">Alterar a imagem</div></span
            >
          </v-img>
        </v-hover>
      </label>
    </v-badge>

    <v-file-input
      v-show="false"
      :value="value"
      :id="imageInputLabel"
      @change="changePhotoSelected"
      accept="image/*"
    >
    </v-file-input>
  </div>
</template>

<script>
export default {
  props: {
    value: null,
    url: {
      default: null,
    },
    addButtonLabel: {
      type: String,
      default: 'Adicionar foto',
    },
    imageInputLabel: {
      type: String,
      default: 'imageInput',
    },
    reset: null,
  },
  data() {
    return {
      photoSelected: null,
    };
  },
  computed: {
    photoSelectedWithoutCache() {
      if (this.photoSelected.startsWith('blob:')) return this.photoSelected;

      return `${this.photoSelected}?${Date.now()}`;
    },
  },
  methods: {
    removeFile() {
      this.photoSelected = null;
      this.$emit('change', null);
      this.$emit('remove', null);
    },
    changePhotoSelected(file) {
      this.photoSelected = URL.createObjectURL(file);
      this.$emit('change', file);
      // //console.log(file)
    },
  },
  watch: {
    url(value) {
      // if(value){
      this.photoSelected = value;
      // }
    },
    reset(value) {
      this.removeFile();
    },
  },
};
</script>

<style>
.opacity-low .v-image__image {
  opacity: 0.1;
  transition: 0.2s;
}
.opacity-medium .v-image__image {
  opacity: 0.5;
  transition: 0.2s;
}

.opacity-high {
  opacity: 1 !important;
  transition: 0.5s;
}
</style>
