<template>
  <v-avatar class="photo-container" v-bind="$attrs" :key="user.url">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-avatar v-if="computedUser.url" class="white-background" v-bind="$attrs" v-on="on">
          <img :src="computedUser.url" alt="" />
        </v-avatar>
        <v-avatar v-if="!computedUser.url" v-bind="$attrs" v-on="on">
          <div class="user-card" v-if="computedUser && computedUser.name">
            {{ firstLetters }}
          </div>
        </v-avatar>
      </template>
      <span>{{ computedUser.name }}</span>
    </v-tooltip>
  </v-avatar>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('config');

export default {
  props: { user: Object },
  computed: {
    ...mapGetters(['getUser']),
    firstLetters() {
      if (this.computedUser) {
        return this.getAbbreviatedString(this.computedUser.name, 2);
      }
    },
    computedUser() {
      return this.user
        ? this.user.name
          ? this.getUser(this.user.id)
          : {}
        : {};
    },
  },
};
</script>

<style scoped>
.user-card {
  background: var(--v-primary-base);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.photo-container{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: white;
}

.white-background{
  background: white
}
</style>