import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  error: undefined,
  attendences: [],
  finishedAttendences: [],
  users: [],
  statuses: [],
  attendenceItems: [],
  environments: [],
  isLoadingAttendences: true,
  isLoadingUsers: true,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
