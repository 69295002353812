import clientsModule from './index';

const MODULE_NAME = 'attendences';

export default rootStore => {
  if (!(MODULE_NAME in rootStore._modules.root._children)) {
    rootStore.registerModule(MODULE_NAME, clientsModule);
  }
};

/*
import productsModule from './index';

const MODULE_NAME = 'config';

export default rootStore => {
  if (!(MODULE_NAME in rootStore._modules.root._children)) {
    rootStore.registerModule(MODULE_NAME, productsModule);
  }
};
*/