import apiClient from '@/utils/api-client-without-base-url';

const suffixEndpoint = '/attendences';
export default {
  getAll: () => apiClient.get(suffixEndpoint),
  get: id => {
    return apiClient.get(`${suffixEndpoint}/${id}`);
  },
  create: data => {
    return apiClient.post(suffixEndpoint, data);
  },
  edit: data => {
    return apiClient.put(`${suffixEndpoint}/${data.id}`, data);
  },
  editStatus: data => {
    return apiClient.put(`${suffixEndpoint}/change-status/${data.id}`, data);
  },
  addResponsible: data => {
    return apiClient.post(`${suffixEndpoint}/add-responsible/${data.attendenceId}`, data);
  },
  delete: id => {
    return apiClient.delete(`${suffixEndpoint}/${id}`);
  },
  getFinished: () => {
    return apiClient.get(`${suffixEndpoint}/getFinished`);
  },
};
