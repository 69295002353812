import {
  CREATE_CLIENT,
  EDIT_CLIENT,
  DELETE_CLIENT,
  LIST_CLIENTS,
  SET_LOADING_CLIENTS,
  CREATE_CLIENT_ADDRESS,
  EDIT_CLIENT_ADDRESS,
  DELETE_CLIENT_ADDRESS,
  LIST_CLIENTS_ADDRESSES,
  SET_LOADING_CLIENTS_ADDRESSES,
} from './mutation-types';

export default {
  [CREATE_CLIENT]: (state, { client }) => {
    state.clients.push(client);
  },
  [EDIT_CLIENT]: (state, { client }) => {
    const indice = state.clients.findIndex(t => t.id === client.id);
    state.clients.splice(indice, 1, client);
  },
  [DELETE_CLIENT]: (state, { client }) => {
    const indice = state.clients.findIndex(t => t.id === client.id);
    state.clients.splice(indice, 1);
  },
  [LIST_CLIENTS]: (state, { clients }) => {
    state.clients = clients;
  },
  [SET_LOADING_CLIENTS]: (state, { isLoadingClients }) => {
    state.isLoadingClients = isLoadingClients;
  },
  [CREATE_CLIENT_ADDRESS]: (state, {clientAddress}) => {
    state.clients.find(el => el.id == clientAddress.clientId).clientAddress = clientAddress
  },
  [EDIT_CLIENT_ADDRESS]: (state, {clientAddress}) => {
    state.clients.find(el => el.id == clientAddress.clientId).clientAddress = clientAddress
  }
};
