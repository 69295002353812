<template>
  <v-text-field
    :placeholder="placeholder"
    solo
    dense
    flat
    clearable
    clear-icon="la-close"
    class="search"
    hide-details
    :value="value"
    @input="$emit('input', $event)"
  >
    <v-icon
      slot="append"
      color="gray"
      size="14px"
    >la-search</v-icon>
  </v-text-field>
</template>
<script>
export default {
  props: {
    placeholder: {
      default: 'Pesquisar'
    },
    value: {
      type: String,
      default: ''
    }
  }
};
</script>
<style scoped>
.search {
  border: 1px solid var(--v-border-base) !important;
  font-size: 0.8rem !important;
  margin: 5px !important;
}
</style>
