import chatsServices from './../Services/chats-services';
import messagesServices from '../Services/messages-services';

import * as types from './mutations-types';

export default {
  [`SOCKET_FAIL_SEND_QUOTED_MSG`]: async ({ commit }, sure ) => {
    commit(types.FAIL_SEND_QUOTED_MSG, sure);
  },
  [`SOCKET_MESSAGE_RECEIVED`]: async ({ commit }, msg) => {
    commit(types.MESSAGE_RECEIVED, msg);
  },
  [`SOCKET_GET_MESSAGES`]: async ({ commit }, messages) => {
    commit(types.GET_MESSAGES, messages);
  },
  [`SOCKET_GET_CONTACTS`]: async ({ commit }, contacts) => {
    commit(types.GET_CONTACTS, contacts);
  },
  [`SOCKET_UPDATE_CHAT`]: ({ commit }, chat) => {
    commit(types.UPDATE_CHAT, chat);
  },
  [`SOCKET_ADD_CHAT`]: async ({ commit }, chat) => {
    commit(types.ADD_CHAT, chat);
  },
  confirmQuotedMsgModal: async({ commit }) => {
    commit(types.FAIL_SEND_QUOTED_MSG, true);
  },
  selectContact: async ({ commit }, contact) => {
    commit(types.SELECT_CONTACT, contact);
  },
  defineNewChat: async ({ commit }, id) => {
    const response = await chatsServices.defineIsNewChat(id);
    commit(types.DEFINE_ISNEWCHAT, response.data);
  },
  filterChats: async ({ commit }, findChat) => {
    commit(types.ALTER_FILTER_CONTACTS, findChat);
  },
  defineDepartmentsChats : async ({ commit }) => {
    commit(types.DEFINE_DEPARTMENTS_CHATS);
  },
  defineFilterContacts: async ({ commit }) => {
    commit(types.DEFINE_APPLIED_FILTER)
  },
  applyFilterTag: async ({ commit }, tag) => {
    commit(types.APPLY_FILTER_TAG, tag);
  },
  applyFilterDepartment: async ({ commit }, department) => {
    commit(types.APPLY_FILTER_DEPARTMENT, department);
  },
  applyFilter: async ({ commit }, filter) => {
    commit(types.APPLY_FILTER, filter);
  },
  finishedChatting: async ({ commit }, chat) => {
    commit(types.FINISHED_CHATTING, chat);
  },
  getChats: async ({ commit }) => {
    let chats = await chatsServices.getAll();
    chats = chats.data;
    commit(types.GET_CONTACTS, chats);
  },
  getMessages: async ({ commit }) => {
    let messages = await messagesServices.getAll();
    messages = messages.data;
    commit(types.GET_MESSAGES, messages);
  },
};
