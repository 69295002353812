<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="slide-x-transition"
    content-class="lite-menu"
    :nudge-width="250"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="user-card">
        <user-photo size=40 width=40 height=40 v-show="loggedUser.name" :user="loggedUser" />
      </div>
    </template>

    <v-card>
      <v-card-text class="py-5 pt-5 pb-2">
        <div class="d-flex justify-space-between">
          <div class="d-flex flex-column mr-5">
            <span class="title">{{ loggedUser.name }}</span>
            <span>{{ loggedUser.email }}</span>
            <span
              class="pb-0 mb-0 pt-2"
              style="cursor: pointer"
              @click="editProfile"
              >Editar perfil</span
            >
          </div>
          <div style="display: flex; align-items: center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="dialog = true"
                >
                  <v-icon class="logout-button">mdi-logout</v-icon>
                </v-btn>

                <v-dialog v-model="dialog" max-width="420">
                  <v-card>
                    <v-card-title class="headline"
                      >Sair mesmo da conta?</v-card-title
                    >
                    <v-card-actions>
                      <v-btn color="black darken-1" text @click="dialog = false"
                        >Cancelar</v-btn
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        class="px-8"
                        depressed
                        @click="logout"
                        >Sair</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <span>Sair da conta</span>
            </v-tooltip>
          </div>
        </div>
        <!-- <div class="mt-5 w-full text-right">{{ loggedUser.companyName }}</div> -->
      </v-card-text>
    </v-card>
    <CreateNewUser
      :selectedUser="loggedUser"
      :hasPermission="false"
      v-model="newUserModal"
      v-if="newUserModal"
      @editUser="changeUser"
      @close="closeModal"
    />
  </v-menu>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('config');
const { mapActions: mapActionsAttendence } =
  createNamespacedHelpers('attendences');

import CreateNewUser from '../../modules/configurations/modals/CreateNewUser.vue';

import UserPhoto from '../../components/utils/UserPhoto.vue';

export default {
  components: {
    UserPhoto,
    CreateNewUser,
  },
  data() {
    return {
      dialog: false,
      menu: false,
      photo: null,
      newUserModal: null,
    };
  },
  methods: {
    ...mapActionsAttendence(['downloadFile']),
    ...mapActions(['editUser']),
    changeUser(event) {
      let dataForm = new FormData();
      dataForm.append('file', event.imageFile[0]);
      Object.keys(event.user).forEach((key) => {
        if (!(event.user[key] == null) && !(event.user[key] == undefined)) {
          dataForm.append(key, event.user[key]);
        }
      });
      this.editUser({ user: dataForm });
    },
    closeModal() {
      this.newUserModal = false;
    },
    editProfile() {
      this.newUserModal = true;
    },
    saveNewUser(e) {
      if (e.user.id) {
      }
    },
    logout() {
      window.localStorage.removeItem('companyId');
      window.localStorage.removeItem('userId');
      window.localStorage.removeItem('auth-token');
      // this.$router.push('/login');
      window.location.href = '/login';
    },
  },
  watch: {
    async 'loggedUser.photoKey'(newVal) {
      if (newVal) {
        this.photo = await this.downloadFile({
          key: 'users/' + window.localStorage.getItem('userId') + '/photo',
        });
      }
    },
  },
  computed: {
    ...mapState(['loggedUser']),
    firstLetters() {
      if (this.loggedUser && this.loggedUser.name)
        return this.getAbbreviatedString(this.loggedUser.name, 2);

      return '';
    },
  },
  async mounted() {
    if (this.loggedUser.photoKey) {
      this.photo = await this.downloadFile({
        key: 'users/' + window.localStorage.getItem('userId') + '/photo',
      });
    }
  },
};
</script>

<style scoped>

.user-card img {
  width: inherit;
}
</style>