import * as types from './mutation-types';
import attendencesService from './../services/attendences-service';
import itemsService from './../services/attendences-items-service';
import paymentsService from './../services/attendences-payments-service';
import historicService from './../services/attendences-historic-service';
import commentariesService from './../services/attendences-commentaries-service';
import statusService from './../services/attendences-status-service';
import schedulesService from './../services/attendences-schedules-service';
import filesService from './../services/attendences-files-service';
import attendencesItemsEnvironmentsService from './../services/attendences-items-environments-service';
import chatsService from './../../Chat/Services/chats-services'

export default {
  getAttendences: async ({ commit }) => {
    const response = await attendencesService.getAll();
    commit(types.LIST_ATTENDENCES, { attendences: response.data });
    commit(types.SET_LOADING_ATTENDENCES, { isLoadingAttendences: false });
  },
  getAttendenceFinished: async ({ commit }) => {
    const response = await attendencesService.getFinished();
    commit(types.LIST_FINISHED_ATTENDENCES, { attendences: response.data });
  },
  editAttendence: async ({ commit }, { attendence }) => {
    const response = await attendencesService.edit(attendence);
    let attendenceObj = response.data;
    attendenceObj = { ...attendence, ...attendenceObj };
    commit(types.EDIT_ATTENDENCE, { attendence: attendenceObj });
  },
  editAttendenceStatus: async ({ commit }, { attendence }) => {
    const response = await attendencesService.editStatus(attendence);
    let attendenceObj = response.data;
    attendenceObj = { ...attendence, ...attendenceObj };
    commit(types.EDIT_ATTENDENCE_STATUS, { attendence: attendenceObj });
    return attendenceObj;
  },
  createAttendence: async ({ commit }, { attendence, user, client, chat }) => {
    try {
      const response = await attendencesService.create(attendence);
      const after = response.data;
      // console.log("attendence: ", attendence, "\nuser: ", user, "\nclient: ", client, "\nchat: ", chat)
      commit(types.CREATE_ATTENDENCE, { attendence: after, user, client, chat });
      return response.data;
    } catch (error) {
      //console.log(error)
    }
  },
  deleteAttendence: async ({ commit }, { attendenceId }) => {
    try {
      const response = await attendencesService.delete(attendenceId);
      commit(types.DELETE_ATTENDENCE, { attendenceId });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  getAttendenceItems: async ({ commit }) => {
    const response = await itemsService.getAll();
    commit(types.LIST_ITEMS, { items: response.data });
  },
  createAttendenceItem: async ({ commit }, { item }) => {
    try {
      const response = await itemsService.create(item);
      commit(types.CREATE_ITEM, { item: response.data });
      return response.data;
    } catch (error) {
      //console.log(error)
    }
  },
  editAttendenceItem: async ({ commit }, { item }) => {
    try {
      const response = await itemsService.edit(item);
      commit(types.EDIT_ITEM, { item: response.data });
      return response.data;
    } catch (error) {
      console.log(error);
      return res.status(400).json('aaa');
    }
  },
  deleteAttendenceItem: async ({ commit }, { itemId, attendenceId }) => {
    const response = await itemsService.delete(itemId);
    commit(types.DELETE_ITEM, { itemId, attendenceId });
  },
  createAttendencePayment: async ({ commit }, { payment }) => {
    try {
      const response = await paymentsService.create(payment);
      commit(types.CREATE_PAYMENT, { payment: response.data });
      return response.data;
    } catch (error) {
      //console.log(error)
    }
  },
  editAttendencePayment: async ({ commit }, { payment }) => {
    const response = await paymentsService.edit(payment);
    commit(types.EDIT_PAYMENT, { payment: response.data });
  },
  deleteAttendencePayments: async ({ commit }, { attendenceId }) => {
    const response = await paymentsService.delete(attendenceId);
    commit(types.DELETE_PAYMENT, { attendenceId });
  },
  createAttendenceHistoric: async ({ commit }, { historic, loggedUser }) => {
    try {
      const response = await historicService.create(historic);
      commit(types.CREATE_HISTORIC, { historic: response.data, loggedUser });
      return response.data;
    } catch (error) {
      //console.log(error)
    }
  },
  getStatuses: async ({ commit }) => {
    const response = await statusService.getAll();
    commit(types.LIST_STATUSES, { statuses: response.data });
    return response.data;
  },
  editStatus: async ({ commit }, { status }) => {
    const response = await statusService.edit(status);

    commit(types.EDIT_STATUS, { status: response.data });
  },
  createAttendenceCommentary: async (
    { commit },
    { commentary, loggedUser }
  ) => {
    try {
      const response = await commentariesService.create(commentary);
      commit(types.CREATE_COMMENTARY, {
        commentary: response.data,
        loggedUser,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  resetUnreadCountOfChat: async ({ commit }, chat) => {
    commit(types.RESET_UNREADCOUNT_CHAT, chat);
  },
  createAttendenceSchedule: async ({ commit }, { schedule }) => {
    try {
      const response = await schedulesService.create(schedule);
      commit(types.CREATE_SCHEDULE, { schedule: response.data });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  editAttendenceSchedule: async ({ commit }, { schedule }) => {
    try {
      const response = await schedulesService.edit(schedule);
      let data = response.data;
      data = { ...schedule, ...data };
      commit(types.EDIT_SCHEDULE, { schedule: data });
      return data;
    } catch (error) {}
  },
  deleteAttendenceSchedule: async (
    { commit },
    { scheduleId, attendenceId }
  ) => {
    const response = await schedulesService.delete(scheduleId);
    commit(types.DELETE_SCHEDULE, { scheduleId, attendenceId });
  },
  getAttendenceFiles: async ({ commit }) => {
    const response = await filesService.getAll();
    commit(types.LIST_FILES, { files: response.data });
    return response.data;
  },
  createAttendenceFile: async ({ commit }, { file }) => {
    try {
      const response = await filesService.create(file);
      commit(types.CREATE_FILE, { file: response.data });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  editAttendenceFile: async ({ commit }, { file }) => {
    const response = await filesService.edit(file);
    commit(types.EDIT_FILE, { file: response.data });
  },
  deleteAttendenceFile: async ({ commit }, { fileId, attendenceId }) => {
    const response = await filesService.delete(fileId);
    commit(types.DELETE_FILE, { fileId, attendenceId });
  },
  downloadFile: async ({ commit }, { key }) => {
    try {
      const response = await filesService.download({ key });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  addAttendenceResponsible: async ({ commit }, { responsible }) => {
    try {
      const response = await attendencesService.addResponsible(responsible);
      commit(types.CREATE_RESPONSIBLE, { responsible: response.data });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  getAttendenceItemsEnvironments: async ({ commit }) => {
    const response = await attendencesItemsEnvironmentsService.getAll();
    commit(types.LIST_ENVIRONMENTS, { environments: response.data });
  },
  createAttendenceItemEnvironments: async ({ commit }, { environment }) => {
    try {
      const response = await attendencesItemsEnvironmentsService.create(
        environment
      );
      commit(types.CREATE_ENVIRONMENT, { environment: response.data });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  editAttendenceItemEnvironments: async ({ commit }, { environment }) => {
    try {
      const response = await attendencesItemsEnvironmentsService.edit(
        environment
      );
      commit(types.EDIT_ENVIRONMENT, { environment: response.data });
      return response.data;
    } catch (error) {
      console.log(error);
      return res.status(400).json(error);
    }
  },
  deleteAttendenceItemEnvironments: async ({ commit }, { environmentId }) => {
    try {
      const response = await attendencesItemsEnvironmentsService.delete(
        environmentId
      );
      commit(types.DELETE_ENVIRONMENT, { environmentId });
      return res.json('excluded');
    } catch (error) {
      console.log(error);
    }
  },
  [`SOCKET_MESSAGE_RECEIVED`]: async ({ commit }, msg) => {
    commit(types.MESSAGE_RECEIVED, msg);
  },
};
