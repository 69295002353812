const Container = () => import('./../views/Container');

const Finished = () => import('./../views/Finished');

export default [
  {
    path: '/finalizados',
    component: Container,
    meta: { permissions: "view_Finalizado" },
    children: [
      {
        path: '',
        name: 'finalizados',
        component: Finished
      },
    ]
  }
];
