<template>
  <v-app>
    <v-main :class="{
      'content-padding': $route.path != '/login' && $route.path != '/chat',
    }">
      <keep-alive ref="keep" include="page" v-if="$route.path != '/login'">
        <!-- <router-view /> -->
        <AppLayout>
          <template>
            <notifications group="error" position="bottom right" />
            <keep-alive>
              <router-view v-if="!isLoadingAttendences"></router-view>
              <v-progress-circular v-else indeterminate :size="65" color="primary">
              </v-progress-circular>
            </keep-alive>
          </template>
        </AppLayout>
      </keep-alive>
      <router-view v-else></router-view>
    </v-main>

    <!-- <iframe
      src="./modules/orders/store/sound-notification.mp3"
      allow="autoplay"
      id="notificationAudio"
      style="display: none"
    ></iframe> -->
    <!-- <audio id="player" muted loop>
      <source
        src="./modules/orders/store/sound-notification.mp3"
        type="audio/mp3"
      />
    </audio> -->
  </v-app>
</template>

<script>
import registerClientStore from './modules/clients/store/register';
import registerUsersStore from './modules/users/store/register';
import registerConfigStore from './modules/configurations/store/register';
import registerAttendenceStore from './modules/attendences/store/register';
import registerCalendarStore from './modules/calendar/store/register';
import registerChatStore from './modules/Chat/store/register';

import { createNamespacedHelpers, mapState } from 'vuex';

const { mapActions: mapActionsClients } = createNamespacedHelpers('clients');
const { mapActions: mapActionsUsers } = createNamespacedHelpers('users');
const { mapActions: mapChatsActions } = createNamespacedHelpers('chat');
const { mapActions: mapActionsConfig } = createNamespacedHelpers('config');
const { mapActions: mapActionsAttendences, mapState: mapStateAttendences } =
  createNamespacedHelpers('attendences');
const { mapActions: mapActionsCalendar } = createNamespacedHelpers('calendar');

export default {
  name: 'App',
  data() {
    return {
      isLoggedIn: false, // determines if dashboard is kept alive or not
      isRouterViewVisible: true,
    };
  },
  computed: {
    ...mapState(['isAppMenuVisible']),
    ...mapStateAttendences(['isLoadingAttendences']),
  },
  methods: {
    ...mapActionsClients(['getClients']),
    ...mapActionsCalendar(['getSchedules']),
    ...mapActionsConfig([
      'getUsers',
      'getUserRoles',
      'editLoggedUser',
      'getTags',
      'getShortcuts',
      'getChatReasons',
      'getDepartments',
      'getMenusChatBot',
      'getOptionsChatBot',
    ]),
    ...mapActionsAttendences([
      'getAttendences',
      'getStatuses',
      'getAttendenceItems',
      'getAttendenceItemsEnvironments',
    ]),
    ...mapChatsActions(['getChats', 'getMessages', 'defineDepartmentsChats']),
    async callAll() {
      await Promise.all([
        registerClientStore(this.$store),
        registerUsersStore(this.$store),
        registerConfigStore(this.$store),
        registerAttendenceStore(this.$store),
        registerCalendarStore(this.$store),
        registerChatStore(this.$store),
        this.getStatuses(),
        this.getUserRoles(),
        this.getUsers(),
        this.getClients(),
        this.getChats(),
        this.getMessages(),
        this.getTags(),
        this.getShortcuts(),
        this.getChatReasons(),
        this.getDepartments(),
        this.getMenusChatBot(),
        this.getOptionsChatBot(),
        this.getAttendences(),
        this.getAttendenceItems(),
        this.getSchedules(),
        this.getAttendenceItemsEnvironments(),
        this.defineDepartmentsChats(),
      ]);
    },
  },
  created() {
    this.callAll();
  },
  watch: {
    $route(to, from) {
      const companyId = window.localStorage.getItem('companyId');

      if (companyId === null) {
        this.isLoggedIn = false;
        // this.$refs.keep.
      } else {
        this.isLoggedIn = true;
        // registerOrderStore(this.$store);
      }
    },
  },
};
</script>

<style>
.content-scroll {
  height: calc(100vh - 73px);
  overflow-y: auto;
  overflow-x: hidden;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background-color: #c9c9c9;
}

.content-scroll::-webkit-scrollbar {
  border-radius: 30px;
  width: 8px;
}

.content-scroll::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 30px;
}

.content-scroll::-webkit-scrollbar-thumb {
  background-color: #c9c9c9;
  border-radius: 30px;
}

.custom-scroll::-webkit-scrollbar {
  border-radius: 30px;
  width: 8px;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 30px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #f0f0f0;
  border-radius: 30px;
}

.table-custom-scroll .v-data-table__wrapper::-webkit-scrollbar {
  border-radius: 30px;
  width: 8px;
}

.table-custom-scroll .v-data-table__wrapper::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 30px;
}

.table-custom-scroll .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: #dedede;
  border-radius: 30px;
}

::-webkit-scrollbar-track-piece {
  background: transparent;
}

html {
  overflow: hidden !important;
}

.bb {
  border-bottom: 1px solid var(--v-border-base);
}

.no-spacing {
  padding-top: 0px !important;
  margin-top: 0px !important;
}

.v-icon.v-icon.no-focus::after {
  display: none;
}

.hover-pointer:hover {
  cursor: pointer !important;
}

.no-border {
  border: none !important;
}

.no-border-and-box-shadow {
  box-shadow: none !;
  border: none !important;
}

.v-application {
  font-family: 'poppins', sans-serif !important;
}

.content-padding {
  padding: 60px 0px 0px 0px !important;
}

/* .display-1,
.display-2,
.display-3,
.display-4,
.headline,
.title,
.subtitle-1,
.subtitle-2,
.body-1,
.body-2,
.caption,
.overline {
  font-family: 'poppins', 'sans-serif'!important;
} */
/* :not(i)  {
  font-family: 'poppins', sans-serif !important;
} */
.v-simple-checkbox .v-icon,
.check-mini .v-icon,
table .v-icon {
  font-size: 20px !important;
}

.v-data-table .mdi-checkbox-marked {
  color: var(--v-primary-base) !important;
}

.mdi-minus-box:before {
  content: '\F0131' !important;
}

.v-btn {
  letter-spacing: 0 !important;
  text-transform: inherit !important;
}

.disabled-button {
  opacity: 0.5;
  pointer-events: none;
}

.form-error {
  color: rgb(250, 41, 41);
  font-size: 11px;
}

.v-application a {
  color: inherit !important;
  text-decoration: inherit !important;
}

[relative] {
  position: relative;
}

[absolute] {
  position: absolute !important;
}

[borderless] {
  border: none;
}

.green2--text {
  color: #00d473;
}

.flex-wrap {
  flex-wrap: wrap;
}

.w-full {
  width: 100%;
}

.v-progress-circular {
  margin-left: 47%;
  margin-top: 20%;
}
</style>
