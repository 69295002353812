import apiClient from '@/utils/api-client-without-base-url';

const suffixEndpoint = '/shortcuts';

export default {
    getAll: () => apiClient.get(suffixEndpoint),

    createShortcut: data => {
        return apiClient.post(suffixEndpoint, data)
    },
    deleteShortcut: id => {
        return apiClient.delete(`${suffixEndpoint}/${id}`)
    },
    editShortcut: data => {
        return apiClient.put(`${suffixEndpoint}/${data.id}`, data)
    }
}
