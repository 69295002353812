<template>
  <div>
    <v-sheet
      class="d-flex align-center justify-space-between"
      :class="{'transparent': isTransparent, 'pa-3': !noPadding}"
      relative
      @click.stop
    >
      <label v-if="label">{{label}}</label>
      <!-- <LineBorder
        v-if="lineBorder"
        :color="color"
        v-bind="lineBorder"
      />-->
      <span v-if="text" class="pl-1">{{text}}</span>
      <v-text-field
        v-if="input"
        :value="value"
        class="text-field-custom"
        v-bind="textFieldOptions"
        @keyup="$emit('keyup', $event)"
        @input="$emit('input', $event)"
        @change="$emit('change', $event)"
      ></v-text-field>
      <InputNumber
        v-if="number"
        :value="value"
        :max="max"
        :isArrowsDisabled="isArrowsDisabled"
        :allowNegativeNumbers="allowNegativeNumbers"
        style="margin-left: 10px"
        :textFieldOptions="textFieldOptions"
        @input="$emit('input', $event)"
      />
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    input: {
      type: Boolean,
      default: false
    },
    allowNegativeNumbers: {
      default: false
    },
    noPadding: {
      default: false,
    },
    number: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      required: false,
      default: null
    },
    isTransparent: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'success'
    },
    isArrowsDisabled: {
      type: Boolean,
      default: false
    },
    text: {},
    label: {},
    textFieldOptions: {},
    value: {},
    lineBorder: {}
  }
};
</script>

<style scoped>
.v-sheet {
  background: var(--v-backgroundSecondary-base);
}
.transparent {
  background-color: 'transparent';
}
</style>
