<template>
  <v-app-bar v-if="pageLoaded && pageNotOnLogin" permanent mini-variant mini-variant-width="30" class="menu" touchless app
    elevate-on-scroll flat>
    <div class="menu-header">
      <img class="logoImage" src="../../assets/img/logoNath.png" alt="" />
    </div>
    <v-list class="content item-list pl-10" dense nav height="100%">
      <v-list-item v-for="item in items" v-bind:key="item.id" class="item" height="100%">
        <router-link :target="item.target" :to="item.routeName"
          :class="{ 'disabled-route': !hasPermission(item.permission) }">
          <v-list-item-action class="item-action" height="100%">
            <p style="color: #177dff; font-weight: 600" v-if="item.routeName == $route.path ||
              item.routeName + '/' == $route.path
              ">
              {{ item.title }}
            </p>
            <template v-else>
              <p v-if="item.routeName != $route.path &&
                hasPermission(item.permission)
                " class="black--text">
                {{ item.title }}
              </p>
              <p v-if="item.routeName != $route.path &&
                !hasPermission(item.permission)
                ">
                {{ item.title }}
              </p>
            </template>
          </v-list-item-action>
        </router-link>
      </v-list-item>
      <!-- <div v-if="isLoaderActive">
          aqui
        </div> -->
    </v-list>
    <v-spacer></v-spacer>
    <div class="content right-items">
      <v-tooltip bottom>
        <template v-slot:activator="{ attrs }">
          <AppUserCard v-bind="attrs" />
        </template>
        <span>Configurações do usuário</span>
      </v-tooltip>
    </div>
  </v-app-bar>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
// import app from "../../App.vue";

const { mapState: mapStateConfig } = createNamespacedHelpers('config');

export default {
  methods: {
    hasPermission(permissionName) {
      if (permissionName && this.loggedUser.role) {
        const has = this.loggedUser.role.permissions.find((el) => {
          return el.permission == permissionName ? true : false;
        });
        if (permissionName == 'view_chat_page' && this.loggedUser.statusWhats == false)
          return false

        if (has)
          return true;

        return false;
      }
      return true;
    },
  },
  computed: {
    ...mapStateConfig(['loggedUser']),
    pageNotOnLogin() {
      return !window.location.href.includes('/login') ? true : false;
    },
  },
  data() {
    return {
      // isLoaderActive: app.data().isLoaderActive,
      pageLoaded: false,
      items: [
        {
          id: 1,
          title: 'Atendimentos',
          routeName: '/atendimentos',
          permission: 'view_attendence',
          exact: true,
          target: "none",
        },
        {
          id: 2,
          title: 'Clientes',
          routeName: '/clientes',
          permission: 'view_client',
          exact: true,
          target: "none",
        },
        {
          id: 3,
          title: 'Agenda',
          routeName: '/agenda',
          permission: 'view_schedule',
          exact: true,
          target: "none",
        },
        {
          id: 5,
          title: 'Finalizados',
          routeName: '/finalizados',
          permission: 'view_Finalizado',
          exact: true,
          target: "none",
        },
        {
          id: 6,
          title: 'Configurações',
          routeName: '/configuracoes',
          permission: 'view_configuration',
          exact: true,
          target: "none",
        },
        {
          id: 7,
          title: 'Chat',
          routeName: '/chat',
          permission: 'view_chat_page',
          exact: true,
          target: "_blank",
        },
      ],
      company: {},
    };
  },
  async created() {
    try {
      // const companyId = window.localStorage.getItem('companyId');
      // const response = await CompanyService.get(companyId);
      // this.company = response.data;
    } catch (err) {
      console.log(err.response);
    }
  },
  mounted() {
    this.pageLoaded = true;
  },
};
</script>

<style>
.disabled-route {
  opacity: 0.5;
  pointer-events: none;
}

.logout-button {
  transform: rotate(180deg);
}

.menu-header {
  display: flex;
}

.menu-header .logoImage {
  align-items: center;
  width: 70px;
  height: auto;
}

/* .v-navigation-drawer {
  background: var(--v-primary-base) !important;
} */

.v-navigation-drawer .v-navigation-drawer__border {
  background-color: var(--v-border-base) !important;
}

.menu-footer {
  transform: rotate(90deg);
}

.content {
  display: flex;
  align-items: center;
}

.item-list {
  background-color: transparent !important;
  padding: 0 !important;
}

.item {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
}

.item>a {
  min-height: 100%;
  display: flex;
}

.item>a>div {
  margin: 0px !important;
}

.item>a>div>p {
  padding-left: 20px;
  padding-right: 20px;
}

.item-action {
  margin: 0;
}

.item-action>p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}

.menu>.v-toolbar__content {
  padding: 0;
}

.menu>a>* {
  height: 100% !important;
}

.v-badge {
  height: fit-content;
  padding: 0 !important;
}

.content.right-items {
  display: flex;
  justify-content: space-between;
  min-width: 80px;
  margin-right: -40px;
}

.content.right-items>* {
  max-width: 70%;
  padding-left: 0px !important;
}

.menu {
  padding-right: 30px !important;
  padding-left: 30px;
  background-color: white !important;
}
</style>
