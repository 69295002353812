import { CLIENT_TYPE } from './../utils/names';
import { mapClients, mapClient } from './../utils/helpers/clients';

/* eslint eqeqeq: "off" */

export default {
  getClientsByName: state => name => state.clients.filter(el => {
    return el.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
  }),
  getClient: state => id => state.clients.find(el => el.id == id)
};
