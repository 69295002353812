<template>
    <svg :width="iconSize" :height="iconSize" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 16C9.85038 16.6303 10.8846 17 12 17C13.1154 17 14.1496 16.6303 15 16" :stroke="iconColor"
            stroke-width="2" stroke-linecap="round" />
        <ellipse cx="15" cy="10.5" rx="1" ry="1.5" :fill="iconColor" />
        <ellipse cx="9" cy="10.5" rx="1" ry="1.5" :fill="iconColor" />
        <path
            d="M15 22H12C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12V15M15 22C18.866 22 22 18.866 22 15M15 22C15 20.1387 15 19.2081 15.2447 18.4549C15.7393 16.9327 16.9327 15.7393 18.4549 15.2447C19.2081 15 20.1387 15 22 15"
            :stroke="iconColor" stroke-width="2" />
    </svg>
</template>
<script>
export default {
  props: {
    iconSize: '',
    iconColor: '',
  },
};
</script>
