<template>
    <svg xmlns="http://www.w3.org/2000/svg" 
    :height="iconSize" 
    viewBox="0 -960 960 960" 
    :width="iconSize"
    >
        <path d="M378-246 154-470l43-43 181 181 384-384 43 43-427 427Z"
        :fill=iconColor
        />
    </svg>
</template>

<script>
export default {
    props: {
        iconColor: '#000000',
        iconSize: '52px'
    }
}
</script>