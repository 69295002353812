<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :height="iconSize"
    viewBox="0 -960 960 960"
    :width="iconSize"
  >
    <path
      :fill="iconColor"
      d="M515.615-80.156 100.001-495.154v-364.845h366.46L881.46-446 515.615-80.156Zm1.444-63.614L816.23-443.154 444.392-814.615H145.385V-515.6l371.674 371.83ZM248.993-667.462q18.161 0 31.161-13.243t13-31.019q0-17.968-12.955-30.968-12.955-12.999-31.115-12.999-18.16 0-31.16 12.954-13 12.955-13 30.923 0 17.968 12.955 31.16 12.954 13.192 31.114 13.192ZM145.385-814.615Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    iconSize: '',
    iconColor: '',
  },
};
</script>
