export const LIST_USERS = 'LIST_USERS';
export const ALTER_USER_CHATBOT = 'ALTER_USER_CHATBOT';
export const ALTER_USER_STATUSWHATS = 'ALTER_USER_STATUSWHATS'; 
export const EDIT_USER = 'EDIT_USER';
export const CREATE_USER = 'CREATE_USER';
export const EDIT_LOGGEDUSER = 'EDIT_LOGGEDUSER';
export const LIST_LOGGEDUSER = 'LIST_LOGGEDUSER';
export const DELETE_USER = 'DELETE_USER';

export const LIST_USERROLESS = 'LIST_USERROLESS';

export const LIST_TAGS = 'LIST_TAGS';
export const DELETE_TAG = 'DELETE_TAG';
export const CREATE_TAG = 'CREATE_TAG';
export const EDIT_TAG = 'EDIT_TAG';

export const LIST_SHORTCUTS = 'LIST_SHORTCUTS'; 
export const CREATE_SHORTCUT = 'CREATE_SHORTCUT';
export const DELETE_SHORTCUT = 'DELETE_SHORTCUT';
export const EDIT_SHORTCUT = 'EDIT_SHORTCUT';

export const LIST_CHATREASONS = 'LIST_CHATREASONS';
export const CREATE_CHATREASON = 'CREATE_CHATREASON';
export const EDIT_CHATREASON = 'EDIT_CHATREASON';
export const DELETE_CHATREASON = 'DELETE_CHATREASON';

export const LIST_DEPARTMENTS = 'LIST_DEPARTMENTS';
export const CREATE_DEPARTMENT = 'CREATE_DEPARTMENTS';
export const EDIT_DEPARTMENT = 'EDIT_DEPARTMENTS';
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENTS';

export const QRCODE_GENERATOR = 'QRCODE_GENERATOR';
export const MESSAGE_QRCODE = 'MESSAGE_QRCODE';

export const LIST_MENUSCHATBOT = 'LIST_MENUSCHATBOT';
export const CREATE_MENUCHATBOT = 'CREATE_MENUCHATBOT';
export const EDIT_MENUCHATBOT = 'EDIT_MENUCHATBOT';
export const DELETE_MENUCHATBOT = 'DELETE_MENUCHATBOT';

export const LIST_OPTIONSCHATBOT = 'LIST_OPTIONSCHATBOT';
export const CREATE_OPTIONCHATBOT = 'CREATE_OPTIONCHATBOT';
export const EDIT_OPTIONCHATBOT = 'EDIT_OPTIONCHATBOT';
export const DELETE_OPTIONCHATBOT = 'DELETE_OPTIONCHATBOT';
export const CHANGE_WELCOME_MENU = 'CHANGE_WELCOME_MENU';

