import apiClient from '@/utils/api-client-without-base-url';

const suffixEndpoint = '/tags';

export default {
    getAll: () => apiClient.get(suffixEndpoint),

    createTag: data => {
        return apiClient.post(suffixEndpoint, data)
    },

    deleteTag: id => {
        return apiClient.delete(`/tag/${id}`)
    },

    editTag: data => {
        return apiClient.put(`/tag/${data.id}`, data)
    },
}
