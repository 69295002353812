<template>
  <div class="page-header">
    <v-row>
      <v-col
        md="auto"
        class="pr-0"
      >
        <h1 class="title-page">{{title}}</h1>
      </v-col>
      <slot></slot>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: ''
    }
  }
};
</script>
<style>
.title-page {
  display: inline-block;
  font-size: 1.9rem;
  font-weight: 500;
  line-height: 1.7;
}
.title-page-2 {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.7;
}

.page-header {
  margin-top: 20px;
  padding: 0px 40px;
}
</style>
