import * as types from './mutations-types';
import chatsServices from '../Services/chats-services';

export default {
  [types.MESSAGE_RECEIVED]: async (state, msg) => {
    state.messages.push(msg);
    state.filterContacts = state.filterContacts.map((item) => {
      if (item.id == msg.chatId) {
        item.timestamp = msg.timestamp;
        if (state.selectedContact.id == item.id) {
          state.selectedContact.timestamp = item.timestamp;
        } else {
          item.unreadCount++;
        }
        return item;
      } else { return item }
    })
    let array = state.filterContacts;
    array = array.sort((a, b) => {
      return a.timestamp - b.timestamp;
    });
    state.filterContacts = array.reverse();
  },
  [types.UPDATE_CHAT]: (state, chat) => {
    if (state.selectedContact.id == chat.id) {
      state.selectedContact = chat;
    }

    state.filterContacts = state.filterContacts.map((el) => {
      if (el.id == chat.id) {
        return chat;
      } else { return el; }
    })

  },
  [types.FAIL_SEND_QUOTED_MSG]: (state, sure) => {
    state.quotedMsgSent = sure;
  },
  [types.GET_MESSAGES]: (state, messages) => {
    state.messages = messages;
  },
  [types.ADD_CHAT]: (state, chat) => {
    chat.isNewChat = true;
    state.filterContacts.unshift(chat);
  },

  [types.GET_CONTACTS]: (state, contacts) => {
    state.contacts = contacts;
  },

  [types.SELECT_CONTACT]: (state, contact) => {
    contact ? state.selectedContact = contact : state.selectedContact = null
  },

  //FILTERS
  [types.DEFINE_APPLIED_FILTER]: (state) => {
    state.filterContacts = state.contacts;
  },
  [types.ALTER_FILTER_CONTACTS]: (state, findChat) => {
    if (findChat) state.findChat = findChat;
    else state.findChat = "";
  },
  [types.DEFINE_ISNEWCHAT]: (state, chat) => {
    const contacts = state.filterContacts.map((el) => {
      if (el?._serialized == chat?._serialized) {
        el.isNewChat = false;
        return el;
      } else {
        return el;
      }
    })
    state.filterContacts = contacts;
  },
  [types.APPLY_FILTER_TAG]: (state, tag) => {
    state.filterContacts = state.contacts.filter((item) => item.tagId == tag.id);
  },
  [types.APPLY_FILTER_DEPARTMENT]: (state, department) => {
    state.filterContacts = state.contacts.filter((item) => item.currentDepartmentId == department.id);
  },
  [types.APPLY_FILTER]: (state, filter) => {
    state.filterApplied = filter;
    if (filter == 'Todas as Seções') {
      state.filterContacts = state.contacts;
    } else if (filter == 'Aguardando Atendimento') {
      state.filterContacts = state.contacts.filter((item) => item.unreadCount);
    } else if (filter == 'Em Atendimento') {
      state.filterContacts = state.contacts.filter((item) => item.isInService);
    } else if (filter == 'Finalizados') {
      state.filterContacts = state.contacts.filter((item) => !item.isInService);
    }
  }
};
