<template>
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        :height="iconSize" 
        viewBox="0 -960 960 960" 
        :width="iconSize">
    <path 
        :fill="iconColor"
        d="M421-311.463 690.537-581l-34.845-34.23L421-380.153 302.539-498.615l-33.846 34.23L421-311.463Zm59.067 211.462q-78.221 0-147.397-29.92-69.176-29.92-120.989-81.71-51.814-51.791-81.747-120.936-29.933-69.146-29.933-147.366 0-78.836 29.92-148.204 29.92-69.369 81.71-120.682 51.791-51.314 120.936-81.247 69.146-29.933 147.366-29.933 78.836 0 148.204 29.92 69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173 0 78.221-29.92 147.397-29.92 69.176-81.21 120.989-51.291 51.814-120.629 81.747-69.337 29.933-148.173 29.933ZM480-145.385q139.692 0 237.154-97.769Q814.615-340.923 814.615-480q0-139.692-97.461-237.154Q619.692-814.615 480-814.615q-139.077 0-236.846 97.461Q145.385-619.692 145.385-480q0 139.077 97.769 236.846T480-145.385ZM480-480Z"/>
    </svg>
</template>
<script>
export default {
    props: {
        iconColor: '',
        iconSize: '',
    }
}
</script>