import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    erro: undefined,
    messages: [],
    contacts: [],
    selectedContact: null,
    findChat: "",
    filterApplied: "Todas as Seções",
    filterContacts: [],
    quotedMsgSent: true,
  };
  
  export default {
    namespaced: true,
    state,
    getters: {
      ...getters,
    },
    mutations: {
      ...mutations,
    },
    actions: {
      ...actions,
    }
  };
  