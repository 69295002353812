<template>
  <v-text-field
    hide-details
    @input="$emit('input', $event)"
    :value="value"
    @keyup="$emit('keyup', $event)"
    @change="$emit('change', $event)"
    v-bind="$attrs"
    autocomplete="nope"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      default: '',
    },
  },
};
</script>
