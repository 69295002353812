import apiClient from '@/utils/api-client-without-base-url';

const suffixEndpoint = '/optionschatbot';

export default {
    getAll: () => apiClient.get(suffixEndpoint),

    createOptionChatBot: data => {
        return apiClient.post(suffixEndpoint, data)
    },

    editOptionChatBot: data => {
        return apiClient.put(suffixEndpoint, data)
    },

    deleteOptionChatBot: data => {
        return apiClient.delete(suffixEndpoint, { data });
    },
}
