import apiClient from '@/utils/api-client-without-base-url';

const suffixEndpoint = '/menuschatbot';

export default {
    getAll: () => apiClient.get(suffixEndpoint),

    createMenuChatBot: data => {
        return apiClient.post(suffixEndpoint, data);
    },

    editMenuChatBot: data => {
        return apiClient.put(`${suffixEndpoint}/${data.id}`, data);
    },

    changeWelcomeMenu: id => {
        return apiClient.put(`${suffixEndpoint}/changewelcomemenu/${id}`);
    },

    deleteMenuChatBot: id => {
        return apiClient.delete(`${suffixEndpoint}/${id}`);
    },
}
